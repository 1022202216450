import { navData } from "./Navdata";
import "./navbar.css";
import { NavLink } from "react-router-dom";

export default function Navbar({ open }: { open: Boolean }) {
  return (
    <div className="d-none d-lg-block position-fixed mt-5">
      <div className={open ? "sidenav" : "sidenavClosed"}>
        {navData.map((item) => (
          <NavLink key={item.id} className={"sideitem"} to={item.link}>
            {item.icon}
            <span className={open ? "linkText" : "linkTextClosed"}>
              {item.text}
            </span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
