import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { connectWallet } from './helperFunctionLaunchpadData'


// Define a type for the slice state
interface createLaunchpad {
  btnTxt: string,
  isConnected: boolean
}

// Define the initial state using that type
const initialState: createLaunchpad = {
  btnTxt: 'Connect Wallet',
  isConnected: false,
}

export const createLaunchpadSlice = createSlice({
  name: 'createLaunchpad',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {

    // Use the PayloadAction type to declare the contents of `action.payload`
    updateWalletStatus: (state, action: PayloadAction<boolean>) => {
      state.isConnected = true
    },

  },
  extraReducers: {
    [connectWallet.pending.type]: (state: any) => {

      state.isConnected = false
    },
    [connectWallet.fulfilled.type]: (state: any, { payload }: any) => {
      state.btnTxt = payload.btnText
      state.isConnected = payload.connected
    },
    [connectWallet.rejected.type]: (state: any) => {
      state.isConnected = false
    }
  }
})

export const { updateWalletStatus } = createLaunchpadSlice.actions



export default createLaunchpadSlice.reducer