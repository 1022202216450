import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getWeb3, loadWeb3 } from "../../connectivity/connectivity";
import { toast } from "react-toastify";

const AddUserToWhitelist = (props: any) => {
    const [data, setData] = useState<string[]>([]);
    const [error, setError] = useState('');
    const handleInputChange = async (event: any) => {
        setError('')

        try {
            let addressArray = event.target.value.split('\n');
            addressArray = addressArray.filter((address: string) => address !== '')


            let arr: any[] = []
            for (let index = 0; index < addressArray.length; index++) {
                let web3 = await getWeb3();
                let isAddress = await web3.utils.isAddress(addressArray[index]);
                if (!isAddress) throw new Error("invalid address:" + addressArray[index]);
                let getCode = await web3.eth.getCode(addressArray[index]);
                if (getCode !== "0x") throw new Error("Enter wallet address instead this:" + addressArray[index]);
                arr.push(addressArray[index])
                setError('')
            }

            setData(arr)
        } catch (error: any) {
            setError(error.message)
            console.log('error while insert address', error.message)
        }

    };

    const removeWhitelistUsers = async () => {
        try {
            let web3 = await getWeb3()
            let launchpadInstance = await new web3.eth.Contract(
                props.launchpadContractAbi,
                props.userAddress
            )
            console.log('launchpadInstance', launchpadInstance)
            let removetxn = await launchpadInstance.methods.removeWhitelistBuyers(data).send({
                from: props.acc
            })
            toast.success('Remove successfully')

            props.setShowUserAddWhitelist(false)
            props.getInfo()
            setData([])
        } catch (error: any) {
            toast.error(error.message)
            console.error('error while remove whitelist user', error)
        }
    }
    const addWhitelistUser = async () => {
        try {
            let web3 = await getWeb3()
            let acc = await loadWeb3()
            let launchpadInstance = await new web3.eth.Contract(
                props.launchpadContractAbi,
                props.userAddress
            )
            let addtxn = await launchpadInstance.methods.setWhitelistBuyers(data).send({
                from: props.acc
            })
            toast.success('Added successfully')

            props.setShowUserAddWhitelist(false)
            props.getInfo()
            setData([])
        } catch (error) {
            console.error('error while add whitelist user', error)
        }

    }
    return <div>
        <Modal show={props.showUserAddWhitelist} centered >
            <Modal.Header >
                <Modal.Title>
                    {props.buttonTitle == 'insert' ? <> Add user whitelist</> : <> Remove user whitelist</>}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <label htmlFor="users">Users</label>
                    <div className="form-group mt-3">
                        <textarea className="text-bg" onChange={handleInputChange} rows={10} cols={52} placeholder="Insert address: one each line 
                     Ex:   
                     0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6
                     0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1
                     0x968136BB860D9534aF1563a7c7BdDa02B1A979C2" />
                    </div>
                    {error && <p className="text-danger">{error}</p>}

                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.setShowUserAddWhitelist(false); setData([]) }}>
                    cancel
                </Button>
                <Button variant="primary" disabled={data.length == 0} onClick={() => {
                    props.buttonTitle == 'insert' ? addWhitelistUser() : removeWhitelistUsers()
                }}>
                    {props.buttonTitle == 'insert' ? <>Insert</> : <>Remove</>}
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default AddUserToWhitelist;
