import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./LaunchPad.css";
import { NavLink } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { upDateFilter, upDateFilterForMyContribution, updateActiveFilter, updateLaunchpadType } from "../../store/feature/launchpadDataSlice";
import { getLaunchapads, getMyContributions } from "../../store/feature/helperFunctionLaunchpadData";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const LaunchPadFilter = ({ open, launchpadTitle }: { open: Boolean, launchpadTitle: string }) => {
  const navigate = useNavigate();
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  let dispatch = useAppDispatch()
  const allLaunchpad = useSelector(
    (state: RootState) => state.launchpadData.allLaunchpad
  );
  const upComing = useSelector(
    (state: RootState) => state.launchpadData.upComing
  );
  const live = useSelector(
    (state: RootState) => state.launchpadData.live
  );
  const ended = useSelector(
    (state: RootState) => state.launchpadData.ended
  );

  const filled = useSelector(
    (state: RootState) => state.launchpadData.filled
  );
  const cancelled = useSelector(
    (state: RootState) => state.launchpadData.cancelled
  );
  const activetab = useSelector(
    (state: RootState) => state.launchpadData.activeTab
  );
  const filterName = useSelector(
    (state: RootState) => state.launchpadData.filterName
  );
  const [activeFilter, setActiveFilter] = useState(
    useSelector((state: RootState) => state.launchpadData.activeFilter)
  );

  let filter = async (addressArray: any) => {
    // let uniqueAddress = addressArray.filter(function (item: any, pos: any) {
    //   return addressArray.indexOf(item) == pos;
    // })
    // uniqueAddress.shift();
    // console.log('uniqueAddress', uniqueAddress)
    // if (activetab == 'all') {
    //   dispatch(upDateFilter(uniqueAddress))
    // }
    // else {
    //   dispatch(upDateFilterForMyContribution(uniqueAddress))
    // }
  }

  const changeRoute = async () => {
    await dispatch(updateLaunchpadType(launchpadTitle))
    navigate('/createlaunch')
  }
  return (
    <div
      className={
        " mt-5 ms-5" + (open ? " margin-side-open" : " margin-side-close")
      }
    >
      <div className="row w-100 gx-0">
        <div className="col-lg-12 col-12 mt-3">
          <div className="d-flex align-items-center">
            <div className="col-lg-4 col-5">
              <h2 className="text-white mob_font">Launchpad List</h2>
            </div>
            <div className="col-lg-7 col-6 ms-auto d-flex gap-3 me-lg-0 me-3">
              <div className="col-lg-7 col-6 d-none d-lg-block" >
                <div className="search ms-3" style={{ visibility: 'hidden' }}>
                  <SearchIcon className="search_icon text-white" />
                  <input
                    type="text"
                    className="form-control height_inp"
                    placeholder="Search"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-12 text-end ms-lg-3 ms-0">

                <button className="login_btn btn_small btn btn-border-3 p-2 height_create me-0 me-lg-0" onClick={() => {
                  changeRoute()
                }} >
                  {launchpadTitle}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row w-100 mt-3">
        <div className="col-lg-12 filter">
          <div className="d-flex">
            <div className="col-lg-10 col-12 d-block d-lg-flex  align-items-center">
              <h6 className="text-white mt-2">Filter BY</h6>
              <div className="d-flex ms-3 res_mar">
                <button
                  className={
                    activeFilter == "all" || activeFilter == 'my'
                      ? "btn_filters avtiveFilter"
                      : "btn_filters"
                  }
                  onClick={() => {
                    setActiveFilter("all");
                    dispatch(updateActiveFilter('all'))

                    // {
                    //   activetab == 'all' ? <>{
                    //     dispatch(getLaunchapads())

                    //   }</> : <>
                    //     {
                    //       btnTxt.length > 20 ?
                    //         dispatch(getMyContributions(btnTxt))

                    //         :
                    //         toast.error('connect wallet')

                    //     }
                    //     </>
                    // }

                  }}
                >
                  All
                </button>
                <button
                  className={
                    activeFilter == "Sale Live"
                      ? "btn_filters live"
                      : "btn_filters"
                  }
                  onClick={() => {
                    setActiveFilter("Sale Live");
                    dispatch(updateActiveFilter('Sale Live'))

                    // filter(live)

                  }}
                >
                  <span className="dot1"></span> Live
                </button>
                <button
                  className={
                    activeFilter == "Upcoming"
                      ? "btn_filters btn_filtersss"
                      : "btn_filters"
                  }
                  onClick={() => {
                    setActiveFilter("Upcoming");
                    dispatch(updateActiveFilter('Upcoming'))

                    // filter(upComing)

                  }}
                >
                  <span className="dot2"></span> Upcoming
                </button>
                <button
                  className={
                    activeFilter == "sale Ended"
                      ? "btn_filters ended"
                      : "btn_filters"
                  }
                  onClick={() => {
                    setActiveFilter("sale Ended");
                    dispatch(updateActiveFilter('sale Ended'))

                    // filter(ended)

                  }}
                >
                  <span className="dot3"></span>Ended
                </button>
                <button
                  className={
                    activeFilter == "filled"
                      ? "btn_filters filled"
                      : "btn_filters "
                  }
                  onClick={() => {
                    setActiveFilter("filled");
                    dispatch(updateActiveFilter('Filled'))

                    // filter(ended)

                  }}
                >
                  <span className="dot3"></span>Filled
                </button>
                <button
                  className={
                    activeFilter == "cancelled"
                      ? "btn_filters avtiveFilter"
                      : "btn_filters"
                  }
                  onClick={() => {
                    setActiveFilter("cancelled");
                    dispatch(updateActiveFilter('Cancelled'))

                  }}
                >
                  <span className="dot3"></span>Cancelled
                </button>
              </div>
            </div>
            {/* <div className="col-lg-2 ms-auto text-end justify-content-end d-none d-lg-block">
              <select
                className="form-select ms-2"
                aria-label="Default select example"
              >
                <option selected>All Chains</option>
                <option value="1">BNB</option>
                <option value="2">USDC</option>
                <option value="2">BUSDT</option>
                <option value="2">BUSD</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchPadFilter;
