import React from 'react'
import EditProfile from '../../Components/Profile/EditProfile'

const ProfileEdit = ({ open }:{open:Boolean}) => {
  return (
    <div className='w-100'>
      <EditProfile open={open}/>
    </div>
  )
}

export default ProfileEdit
