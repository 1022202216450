import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWeb3, loadWeb3, remoteWeb3 } from "../../connectivity/connectivity";
import { launchpadFactoryAddress, privateSalelaunchpadFactoryAddress } from "../../contract/Contract";
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import launchpadFactoryAbi from '../../contract/abis/launchpadFactoryAbi.json'
import privateSaleLaunchpadFactoryAbi from '../../contract/abis/privateSaleLaunchpadFactoryAbi.json'
import { createLaunchpadInfo } from "../../interfaces/launchpad";
import { toast } from "react-toastify";
import { walletShortFormer } from "../../utils";

export const getLaunchapads = createAsyncThunk(
  "getLaunchpad",
  async () => {
    try {
      const remote: any = remoteWeb3()

      let launchpadFactoryInstance = await new remote.eth.Contract(
        launchpadFactoryAbi,
        launchpadFactoryAddress
      );
      let totalLaunchpadsAddress = await launchpadFactoryInstance.methods.totalLaunchpads().call()
      console.log('totalLaunchpadsAddress', totalLaunchpadsAddress)
      return totalLaunchpadsAddress;
    } catch (error) {
      console.error("error while get launcahpad", error);

    }
  }
)
export const getPrivateLaunchapads = createAsyncThunk(
  "PrivatelaunchpadData",
  async () => {
    try {
      const remote: any = remoteWeb3()

      let launchpadFactoryInstance = await new remote.eth.Contract(
        privateSaleLaunchpadFactoryAbi,
        privateSalelaunchpadFactoryAddress
      );
      let totalLaunchpadsAddress = await launchpadFactoryInstance.methods.totalLaunchpads().call()
      console.log('privatetotalLaunchpadsAddress', totalLaunchpadsAddress)
      return totalLaunchpadsAddress;
    } catch (error) {
      console.error("error while get launcahpad", error);

    }
  }
)
export const getMyContributions = createAsyncThunk("launchpadData/myContributions",
  async (userAddress: string, { rejectWithValue }) => {
    try {
      const remote: any = remoteWeb3()

      let launchpadFactoryInstance = await new remote.eth.Contract(
        launchpadFactoryAbi,
        launchpadFactoryAddress
      );
      let totalLaunchpadsAddressByUser = await launchpadFactoryInstance.methods.totalLaunchpadsByUser(userAddress).call()
      console.log('totalLaunchpadsAddress', totalLaunchpadsAddressByUser)
      return totalLaunchpadsAddressByUser;
    } catch (error) {
      console.error("error while get launcahpad", error);

    }
  }
)
export const getPrivateMyContributions = createAsyncThunk("launchpadData/myContributions",
  async (userAddress: string, { rejectWithValue }) => {
    try {
      const remote: any = remoteWeb3()

      let launchpadFactoryInstance = await new remote.eth.Contract(
        privateSaleLaunchpadFactoryAbi,
        privateSalelaunchpadFactoryAddress
      );
      let totalLaunchpadsAddressByUser = await launchpadFactoryInstance.methods.totalLaunchpadsByUser(userAddress).call()
      console.log('totalLaunchpadsAddress', totalLaunchpadsAddressByUser)
      return totalLaunchpadsAddressByUser;
    } catch (error) {
      console.error("error while get launcahpad", error);

    }
  }
)
export const connectWallet = createAsyncThunk("connectWallet", async () => {
  try {


    let acc = await loadWeb3();
    console.log("acc", acc);
    let obj = {}
    if (acc == "No Wallet") {
      toast.error("please install metamask");
      return {
        btnText: 'Connect Wallet',
        connected: false
      }
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network please connect bsc test net");
      return {
        btnText: 'Connect Wallet',
        connected: false
      }
    } else {
      return {
        btnText: acc,
        connected: true
      }
      // return acc

    }
  } catch (error) {
    console.log(error)
  }
})