import React from 'react'
import DashboardCard from '../../Components/DashboardCards.tsx/DashboardCard'
import DashboardTabs from '../../Components/DashboardCards.tsx/DashboardTabs'
import PrivateDashboardTabs from '../../Components/DashboardCards.tsx/PrivateDashboardTabs'
const PrivateDashboardpage = ({ open }:{open:Boolean}) => {
  return (
    <div className='w-100 flex-wrap'>
      <PrivateDashboardTabs open={open}/>
      {/* <DashboardCard open={open}/> */}
    </div>
  )
}

export default PrivateDashboardpage
