import { useEffect, useState } from "react";
import "./navbar.css";
import Button from "react-bootstrap/Button";
import { TopNavProps } from "../../interfaces/navbar";
import {
  HiOutlineBars3BottomRight,
  HiOutlineBars3CenterLeft,
} from "react-icons/hi2";
import finallogo from "../../assets/finallogo.gif";
import blacklogo from "../../assets/blacklogo.gif";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadWeb3 } from "../../connectivity/connectivity";
import { updateWalletStatus } from "../../store/feature/createLaunchpadSlice";
import { toast } from "react-toastify";
import { walletShortFormer } from "../../utils";
import { connectWallet } from "../../store/feature/helperFunctionLaunchpadData";
import { useAppDispatch } from "../../store/store";
import { NavLink } from "react-router-dom";
const TopNav = ({ setOpen, open, handleShow }: TopNavProps) => {
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  const dispatch = useAppDispatch();
  const getaccount = () => {
    dispatch(connectWallet());
  };
  const toggleOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [theme, setTheme] = useState<null | string>("");
  const toggleThem = () => {
    if (theme === "dark-theme") {
      localStorage.setItem("theme", "light-theme");
      setTheme("light-theme");
    } else {
      setTheme("dark-theme");
      localStorage.setItem("theme", "dark-theme");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("theme") == null) {
      document.body.className = "dark-theme";
      localStorage.setItem("theme", "dark-theme");
      setTheme("dark-theme");
    } else {
      document.body.className = localStorage.getItem("theme")!;
      setTheme(localStorage.getItem("theme"));
    }
  }, [theme]);
  useEffect(() => {
    if ((window as any).ethereum) {
      (window as any).ethereum.on("accountsChanged", function (accounts: any) {
        getaccount();
      });
    }
  }, []);

  return (
    <>
      <div className="bor p-1 d-flex fs-4 ">
        <button
          className={"menuBtn position-absolute d-none d-lg-block"}
          onClick={toggleOpen}
        >
          {open ? <HiOutlineBars3BottomRight /> : <HiOutlineBars3CenterLeft />}
        </button>
        {/* FOR MOBILE TOGGLE BTN */}
        <Button onClick={handleShow} className="d-block d-lg-none mobile_btn">
          <HiOutlineBars3CenterLeft className="fs-1" />
        </Button>

        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Link to="/">
            <div className="logo px-5 d-none d-lg-block">
              {/* <h3 className="text-white d-none d-lg-block">LOGO...</h3> */}
              <img
                src={theme === "dark-theme" ? finallogo : blacklogo}
                alt="logo"
                width="120px"
              />
            </div>
            </Link>
           

            <div className="form-check form-switch switch p-0 d-none">
              <input
                className="form-check-input change ms-auto  light_mode me-3 mt-2"
                onClick={() => toggleThem()}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
            </div>
            <div className="login ms-auto">
              <button
                className="login_btn btn btn-border-3 me-2"
                onClick={getaccount}
              >
                {isConnected ? walletShortFormer(btnTxt) : btnTxt}
              </button>
              {/* <Link to="/profile">
                <button className=" btn btn-border-3">Profile</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="top-nav-margin-bottom"></div>
    </>
  );
};

export default TopNav;
