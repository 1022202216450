import React from 'react'
import Landingpage from '../../Components/LandingPage/Landingpage'

const Landing = ({ open }:{ open: Boolean}) => {
  return (
    <div className='w-100 flex-wrap'>
      <Landingpage  open={open}/>
    </div>
  )
}

export default Landing
