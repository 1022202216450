import * as React from "react";
import { useState, ChangeEvent, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./CreateLaunchPad.css";
import {
  DexInfo,
  FeeSystem,
  LaunchpadInfo,
  SocialLinks,
  calculateToken,
  claimInfo,
} from "../../interfaces/launchpad";
import { tokenAbi } from "../../utilies/Contract";
import { getWeb3, loadWeb3 } from "../../connectivity/connectivity";
import * as Yup from "yup";
import { useFormik } from "formik";
import { walletShortFormer } from "../../utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Buffer } from "buffer";
import TextareaAutosize from "@mui/base/TextareaAutosize";

import { tokenDetail, name } from "../../interfaces/verifyToken";
import closeIcon from "../../assets/logo/closeIcon.png";
import tokenLogo from "../../assets/logo/user.png";
import { toast } from "react-toastify";

import { create, CID, IPFSHTTPClient } from "ipfs-http-client";
import { useSelector } from "react-redux";
import {
  launchpadFactoryAddress,
  privateSalelaunchpadFactoryAddress,
  virtualRouterAddress,
  virtualfactoryAddress,
} from "../../contract/Contract";
import icoTokenAbi from '../../contract/abis/icoTokenAbi.json'
import launchpadFactoryAbi from '../../contract/abis/launchpadFactoryAbi.json'
import privateSaleLaunchpadFactoryAbi from '../../contract/abis/privateSaleLaunchpadFactoryAbi.json'
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'

import Web3 from "web3";
import { userInfo } from "os";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toPlainString } from "../../utilies/utils";
import { RootState } from "../../store/store";
import AddUserToWhitelistPrivateSale from "../Modals/AddUserToWhitelistPrivateSale";
const ZOOM = 100;
const projectId = "2PVQuZ3ioyP4EFf8CpGkVwCe5XB";
const projectSecret = "86a676ceca834ff60ba9bf7b425fb604";
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);
const steps = [
  "Verify Token",
  "DeFi Launchpad Info",
  "Time & Duration",
  "Additional info",
  "Summery",
];
// const URL =
//   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export default function LaunchPadSteps({ open }: { open: Boolean }) {
  const [showUserAddWhitelist, setShowUserAddWhitelist] = useState(false);
  const [addresses, setAddresses] = useState<any>([]);

  const navigate = useNavigate();
  const launchpadType = useSelector(
    (state: RootState) => state.launchpadData.launcpadType
  );

  let ipfs: IPFSHTTPClient | undefined;
  try {
    ipfs = create({
      url: "https://ipfs.infura.io:5001/api/v0",
      headers: {
        authorization,
      },
    });
  } catch (error) {
    console.error("IPFS error ", error);
    ipfs = undefined;
  }
  const [flatFee, setFlatFee] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [feeToken, setFeeToken] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [skipped, setSkipped] = useState(new Set<number>());
  const [tokenStatus, setTokenStatus] = useState("conect & verify");
  const [vesting, setVesting] = useState(false);
  const [requiredTokens, setRequiredTokens] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isApproved, setIsApproved] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [createLogoLoading, setCreateLogoLoading] = useState(false);
  const [isApprovedLoadding, setIsApprovedLoadding] = useState(false);
  const intialValues = { softcap: 0, hardcap: 0 };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({
    hardcap: "hardcap is required",
    softcap: "softcap is required",
  });
  const [tokenInfo, setTokenInfo] = useState({
    tokenName: "",
    tokenDecimal: 0,
    tokenSymbol: "",
    tokenBalance: 0,
  });
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isConnected } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );

  const handleApprove = async () => {
    setIsApprovedLoadding(true);
    try {
      const web3 = await getWeb3();
      const acc = await loadWeb3();
      console.log("web3", web3);
      console.log("acc", acc);
      let tokenInstance = await new web3.eth.Contract(
        icoTokenAbi,
        formik.values.contractAdress
      );
      let balance = await tokenInstance.methods.balanceOf(acc).call();
      console.log("balance of", balance);
      console.log('requiredToken', requiredTokens);
      let allowance
      if (launchpadType == 'Create private Sale') {
        allowance = await tokenInstance.methods.allowance(acc, privateSalelaunchpadFactoryAddress).call();

      }
      else {
        allowance = await tokenInstance.methods.allowance(acc, launchpadFactoryAddress).call();

      }
      console.log('allowance', allowance);
      if (allowance < requiredTokens) {
        if (Number(requiredTokens) <= Number(balance)) {
          if (launchpadType == 'Create private Sale') {
            let approveHash = await tokenInstance.methods
              .approve(privateSalelaunchpadFactoryAddress, requiredTokens)
              .send({
                from: acc,
              });
          }
          else {
            let approveHash = await tokenInstance.methods
              .approve(launchpadFactoryAddress, requiredTokens)
              .send({
                from: acc,
              });
          }

          toast.success("Successfully Approved");
          setIsApprovedLoadding(false);
          setIsApproved(false);

        } else {
          toast.error(
            `You don't have enough ${tokenInfo.tokenSymbol} tokens to create launchpad`
          );
          setIsApprovedLoadding(false);
        }
      }

    } catch (error) {
      setIsApprovedLoadding(false);
    }

    setIsApprovedLoadding(false);
  };
  const handleUploadClick = () => {

    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setCreateLogoLoading(true);
    if (!e.target.files) {
      setCreateLogoLoading(false);

      return;
    }
    console.log("e.target.files[0]", e.target.files[0]);

    const result = await (ipfs as IPFSHTTPClient).add(e.target.files[0]);
    console.log("result", result);
    setFile(e.target.files[0]);
    const url = "https://ipfs.io/ipfs/" + result.cid.toString();
    console.log("url", url);
    formik.setValues({
      ...formik.values,
      logoUrl: url,
    });
    setCreateLogoLoading(false);
  };
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const calculateToken = async () => {

    try {
      const web3 = await getWeb3();

      let launchpadFactoryInstance
      if (launchpadType == 'Create private Sale') {
        launchpadFactoryInstance = await new web3.eth.Contract(
          privateSaleLaunchpadFactoryAbi,
          privateSalelaunchpadFactoryAddress
        );
      }
      else {
        launchpadFactoryInstance = await new web3.eth.Contract(
          launchpadFactoryAbi,
          launchpadFactoryAddress
        );
      }


      let raisedTokenFeePercent: number;
      let raisedFeePercent: number;
      if (formik.values.currency == "bnb") {
        raisedTokenFeePercent = 0;
        raisedFeePercent = 5 * ZOOM;
      } else {
        raisedTokenFeePercent = 5 * ZOOM;
        raisedFeePercent = 0;
      }

      // parameters
      let _feeToken: string = feeToken;
      let _presalerate: string | undefined =
        typeof formik.values.presaleRate === undefined
          ? 0
          : web3.utils.toWei(toPlainString(String(formik.values.presaleRate)));
      let _hardcap: string = web3.utils.toWei(toPlainString(formValues.hardcap.toString()));
      let _raisedTokenFeePercent: string = raisedTokenFeePercent.toString();
      let _raisedFeePercent: string = raisedFeePercent.toString();
      let _listingPercent: string = (
        formik.values.routerLiquidity * ZOOM
      ).toString();
      let _listingPrice: string = web3.utils.toWei(
        toPlainString(String(formik.values.routerListingRate))
      );

      // array to pass in the function.
      let arr: calculateToken = [
        _feeToken,
        _presalerate,
        _hardcap,
        _raisedTokenFeePercent,
        _raisedFeePercent,
        _listingPercent,
        _listingPrice,
      ];

      let requiredTokens = await launchpadFactoryInstance.methods
        .calculateTokens(arr)
        .call();
      setRequiredTokens(requiredTokens[0]);
      console.log("arr", requiredTokens[0]);
    } catch (error: any) {
      console.error("error while calculate token", error.message);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = async () => {
    try {
      let acc = await loadWeb3();
      const web3 = await getWeb3();

      let launchpadFactoryInstance
      if (launchpadType == 'Create private Sale') {
        launchpadFactoryInstance = await new web3.eth.Contract(
          privateSaleLaunchpadFactoryAbi,
          privateSalelaunchpadFactoryAddress
        );
      }
      else {
        launchpadFactoryInstance = await new web3.eth.Contract(
          launchpadFactoryAbi,
          launchpadFactoryAddress
        );
      }

      // Launchpad Info Data
      let _icoToken: string = formik.values.contractAdress;
      let _feeToken: string = feeToken;
      let _softCap: string = web3.utils.toWei(toPlainString(String(formValues.softcap)));
      let _hardCap: string = web3.utils.toWei(toPlainString(String(formValues.hardcap)));
      let _presaleRate: string = web3.utils.toWei(
        toPlainString(String(formik.values.presaleRate))
      );
      let _minInvestment: string = web3.utils.toWei(
        toPlainString(String(formik.values.minimumBuy))
      );
      let _maxInvestment: string = web3.utils.toWei(
        toPlainString(String(formik.values.maximumBuy))
      );
      let _startTime: number = Math.floor(
        new Date(formik.values.startTime).getTime() / 1000
      );
      let _endTime: number = Math.floor(
        new Date(formik.values.endTime).getTime() / 1000
      );
      let _wlPool: number = formik.values.whitelist === "disable" ? 0 : 1;
      let _poolType: number = Number(formik.values.refundType);
      let launchpadInfo

      if (launchpadType == 'Create private Sale') {
        launchpadInfo = [
          _icoToken,
          _feeToken,
          _softCap,
          _hardCap,
          _presaleRate,
          _minInvestment,
          _maxInvestment,
          _startTime,
          _endTime,
          _poolType,
          addresses
        ];
      }
      else {
        let launchpadInfos: LaunchpadInfo = [
          _icoToken,
          _feeToken,
          _softCap,
          _hardCap,
          _presaleRate,
          _minInvestment,
          _maxInvestment,
          _startTime,
          _endTime,
          _wlPool,
          _poolType,
        ];
        launchpadInfo = launchpadInfos
      }


      // Launhpad Claim Info

      let _firstReleasePercent: number = formik.values.firstReleasePresale * ZOOM;
      let _vestingPeriodEachCycle: number = formik.values.vestingPeriod * 60; //convert into seconds
      let _tokenReleaseEachCycle: number = formik.values.presaleTokenPercent * ZOOM;
      let claimInfo: claimInfo = [
        _firstReleasePercent,
        _vestingPeriodEachCycle,
        _tokenReleaseEachCycle,
      ];

      //DexInfo
      let _manualListing: boolean = formik.values.listing === 'Manual Listing';
      let _routerAddress: string = virtualRouterAddress;
      let _factoryAddress: string = virtualfactoryAddress;
      let _listingPrice: string = web3.utils.toWei(toPlainString(String(formik.values.routerListingRate)));
      let _listingPercent: number = formik.values.routerLiquidity * ZOOM;
      let _lpLockTime: number = Number(formik.values.lockupMinutes) * 60;
      let dexInfo: DexInfo = [
        _manualListing,
        _routerAddress,
        _factoryAddress,
        _listingPrice,
        _listingPercent,
        _lpLockTime,
      ];

      //FeeSystem
      let _raisedFeePercent: number = (formik.values.currency == "bnb") ? 5 * ZOOM : 0;
      let _raisedTokenFeePercent: number = (formik.values.currency == "bnb") ? 0 : 5 * ZOOM;;

      let feeSystem: FeeSystem = [
        _raisedFeePercent,
        _raisedTokenFeePercent,

      ];

      // social links
      let _logoURL: string = formik.values.logoUrl;
      let _description: string = formik.values.discription;
      let _websiteURL: string = formik.values.websiteUrl;
      let _facebookURL: string = formik.values.facebookUrl;
      let _twitterURL: string = formik.values.twitterUrl;
      let _githubURL: string = formik.values.githubUrl
      let _telegramURL: string = formik.values.telegramUrl
      let _instagramURL: string = formik.values.instagramUrl;
      let _discordURL: string = formik.values.discordUrl;
      let _redditURL: string = formik.values.redditUrl;
      let socialLinks: SocialLinks = [
        _logoURL,
        _description,
        _websiteURL,
        _facebookURL,
        _twitterURL,
        _githubURL,
        _telegramURL,
        _instagramURL,
        _discordURL,
        _redditURL,
      ];

      if (Date.now() / 1000 > _startTime) {
        toast.error("Start time must be greater than curren time");
      }
      else {
        let tx = await launchpadFactoryInstance.methods.deployLaunchpad(
          launchpadInfo,
          claimInfo,
          dexInfo,
          feeSystem,
          socialLinks
        )
        const gasEstimate = await tx.estimateGas({ from: acc });

        tx = await launchpadFactoryInstance.methods.deployLaunchpad(
          launchpadInfo,
          claimInfo,
          dexInfo,
          feeSystem,
          socialLinks
        )
          .send({
            from: acc,
            value: web3.utils.toWei(toPlainString(String(flatFee)))
          });
        toast.success("Launchpad created successfuly");
        setIsApproved(true);
        let launchpadAddress = tx.events.NewLaunchpad.returnValues.launchpad
        if (launchpadType == 'Create private Sale') {
          navigate(`/privateview/${launchpadAddress}`);
        }
        else{

          navigate(`/view/${launchpadAddress}`);
        }
      }

    } catch (error: any) {
      let errorString = error.toString();
      let match = errorString.match(/"message": "(.*?)"/);
      let message
      if (match) {
        message = match[1];
        console.log(message); // "execution reverted: Pair rate is less than 0.0001"

      } else {
        message = error.toString().replace("Error: ", "");
      }
      toast.error(message)

    }


  };
  const getCreationFee = async () => {
    await loadWeb3()
    const web3 = await getWeb3();
    let launchpadFactoryInstance
    if (launchpadType == 'Create private Sale') {

      launchpadFactoryInstance = await new web3.eth.Contract(
        privateSaleLaunchpadFactoryAbi,
        privateSalelaunchpadFactoryAddress
      );
    }
    else {
      launchpadFactoryInstance = await new web3.eth.Contract(
        launchpadFactoryAbi,
        launchpadFactoryAddress
      );
    }
    let fee = await launchpadFactoryInstance.methods.flatFee().call()
    setFlatFee(String(web3.utils.fromWei(fee)))

  }
  React.useEffect(() => {
    getCreationFee()
    // handleSubmit();
  }, []);
  const handlepercentage = async (e: any, formik: any) => {
    formik.handleChange(e);

    let result = Number(e.target.value) + Number(e.target.name == 'firstReleasePresale' ? formik.values.presaleTokenPercent : formik.values.firstReleasePresale)
    if (result > 100) {
      setTimeout(() => {
        formik.setErrors({
          ...formik.errors,
          presaleTokenPercent: "First release for presale and Percent token release each cycle must be less than 100 percent",
          firstReleasePresale: "First release for presale and Percent token release each cycle must be less than 100 percent",
        });
      }, 100);
    }
  };
  // console.log('value',value)
  const createLaunchpadSchema = Yup.object().shape({
    contractAdress: Yup.string().required(
      "Contract Address is a required field"
    ),
    presaleRate: Yup.number().required("Rate is required").min(1, 'must be greater then 0'),

    minimumBuy: Yup.number().required("minimumBuy is required"),
    maximumBuy: Yup.number().max(formValues.hardcap, 'must be less then hardcap').required("maximumBuy is required"),
    routerListingRate: Yup.number()
      .required("Router Listing Rate is required")
      .min(1, "must be greater then 0"),
    routerLiquidity: Yup.number()
      .required("Router Liquidity is required")
      .min(51, "must be greater then 50%")
      .max(100, "must be less then or equal to 100%"),
    startTime: Yup.date()
      .required("Must Enter Start time")
      .min(new Date(), "date must be greater then current date"),
    endTime: Yup.date()
      .required("Must enter end time")
      .min(Yup.ref("startTime"), "End date must be after start date"),
    firstReleasePresale: Yup.number()
      .min(1, "must be greater then 0")
      .max(100, "must be less the or equal to 100")
      .required("pressale first release is requird field"),
    vestingPeriod: Yup.number()
      .min(1, "must be greater then 0")
      .required("vesting period is requird field"),
    presaleTokenPercent: Yup.number()
      .min(1, "must be greater then 0")
      .max(100, "must be less the or equal to 100")
      .required("pressale token is requird field"),
    lockupMinutes: Yup.number()
      .min(0, "Enter positive number")
      .required("lockup is required field"),
    logoUrl: Yup.string()
      .matches(
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        "Enter correct url!"
      )
      .required("Please enter url"),
    websiteUrl: Yup.string()
      .required("website url is a requird field")
      .matches(
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

        "Enter correct url!"
      ),
    discordUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    facebookUrl: Yup.string().matches(
      /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
      "Enter correct url!"
    ),
    twitterUrl: Yup.string().matches(
      /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
      "Enter correct url!"
    ),
    githubUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    instagramUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    redditUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    telegramUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
  });
  const formik = useFormik({
    initialValues: {
      contractAdress: "",
      currency: "bnb",
      listing: "Auto Listing",
      presaleRate: undefined,
      whitelist: "disable",
      minimumBuy: 0,
      maximumBuy: 0,
      routerLiquidity: 0,
      routerListingRate: 0,
      routerType: "Virtual Router",
      refundType: "0",
      startTime: "",
      endTime: "",
      firstReleasePresale: 0,
      vestingPeriod: 0,
      presaleTokenPercent: 0,
      logoUrl: "",
      discription: "",
      websiteUrl: "",
      discordUrl: "",
      twitterUrl: "",
      facebookUrl: "",
      redditUrl: "",
      githubUrl: "",
      telegramUrl: "",
      instagramUrl: "",
      lockupMinutes: "",
    },
    validationSchema: createLaunchpadSchema,

    onSubmit: async (values, action) => {
      // await createLaunchpad(values);
      // action.resetForm();
    },
  });
  const checkAddress = async (formik: any) => {
    if (!isConnected) {
      toast.error("plesae connect wallet");
    } else {
      setIsVerified(true)
      console.log("contractAddress", formik.values.contractAdress);
      let acc = await loadWeb3();
      const web3: any = (window as any).web3;
      let _addressStatus;
      const _address = formik.values.contractAdress;
      let obj: tokenDetail = {
        tokenName: "",
        tokenDecimal: 0,
        tokenSymbol: "",
        tokenBalance: 0,
      };
      try {
        let isAddress = await web3.utils.isAddress(_address);
        if (!isAddress) throw new Error("isAddress");

        let getCode = await web3.eth.getCode(_address);
        if (getCode === "0x") throw new Error("getCode");

        let isErc20;
        isErc20 = await web3.eth.call({
          to: _address,
          data: web3.utils.sha3("totalSupply()"),
        });

        let tokenInstance = new web3.eth.Contract(tokenAbi, _address);
        let tokenName: string,
          tokenSymbol: string,
          tokenDecimal: number,
          tokenBalance: number;
        tokenName = await tokenInstance.methods.name().call();
        tokenSymbol = await tokenInstance.methods.symbol().call();
        tokenDecimal = await tokenInstance.methods.decimals().call();

        tokenBalance = await tokenInstance.methods.balanceOf(acc).call();

        tokenBalance = await web3.utils.fromWei(tokenBalance);

        obj.tokenName = tokenName;
        obj.tokenSymbol = tokenSymbol;
        obj.tokenDecimal = tokenDecimal;
        obj.tokenBalance = tokenBalance;

        setTokenStatus("Verified");
        setIsValid(false);
        setIsVerified(false)


        setTokenInfo({ ...obj });
      } catch (error) {
        console.error("error while verifying address", error);
        formik.setErrors({
          contractAdress: "Invalid Address",
        });
        setTokenStatus("conect & verify");
        setTokenInfo(obj);
        setIsValid(true);
        setIsVerified(false)

      }
    }
  };
  const maximumbuy = async (e: any, formik: any) => {
    if (Number(e.target.value == 0)) {
      setTimeout(() => {
        formik.setErrors({
          ...formik.errors,
          maximumBuy: "maximumbuy is required",
        });
      }, 100);
      formik.handleChange(e);
    } else {
      if (Number(e.target.value) < Number(formik.values.minimumBuy)) {
        setTimeout(() => {
          formik.setErrors({
            ...formik.errors,
            maximumBuy: "maximumBuy buy must be greater then minimum buy",
          });
        }, 100);
        formik.handleChange(e);
      } else {
        formik.handleChange(e);
      }
    }
  };
  const minimumbuy = async (e: any, formik: any) => {
    if (Number(e.target.value == 0)) {
      setTimeout(() => {
        formik.setErrors({
          ...formik.errors,
          minimumBuy: "minimumBuy is required",
        });
      }, 100);
      formik.handleChange(e);
    } else {
      if (e.target.value > formik.values.maximumBuy) {
        setTimeout(() => {
          formik.setErrors({
            ...formik.errors,
            minimumBuy: "minimumBuy buy must be less then maximumbuy buy",
          });
        }, 100);
        formik.handleChange(e);
      } else {
        formik.handleChange(e);
      }
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name == "softcap") {
      let softcapValue = value;
      if (softcapValue == 0) {
        setFormErrors({
          ...formErrors,
          softcap: "softcap is required",
        });
      } else if (Number(softcapValue) >= Number(formValues.hardcap)) {
        setFormErrors({
          ...formErrors,
          softcap: "softcap values must be less the hardcap value",
        });
      } else {
        let result = Number(softcapValue) >= (Number(formValues.hardcap) * 40 / 100);
        if (!result) {
          setFormErrors({
            ...formErrors,
            softcap: "value must be greater then or equal to 40% of hard cap",
          });
        } else {
          setFormErrors({
            ...formErrors,
            softcap: "",
          });
        }
      }
    } else if (name == "hardcap") {
      let hardcapValue = value;
      if (hardcapValue == 0) {
        setFormErrors({
          ...formErrors,
          hardcap: "hardcap is required",
        });
      } else if (Number(hardcapValue) <= Number(formValues.softcap)) {
        setFormErrors({
          ...formErrors,
          softcap: "softcap value must be less then hardcap value",
          hardcap: "",
        });
      } else {
        let result = Number(formValues.softcap) >= (Number(hardcapValue) * 40 / 100);
        if (!result) {
          setFormErrors({
            ...formErrors,
            softcap: "value must be greater then or equal to 40% of hard cap",
            hardcap: "",
          });
        } else {
          setFormErrors({
            ...formErrors,
            softcap: "",
            hardcap: "",
          });
        }
      }
    }
  };

  return (
    <div
      className={
        " mt-5 ms-5" + (open ? " margin-side-open" : " margin-side-close")
      }
    >
      <Box sx={{ marginTop: "65px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step className="text-white" key={label} {...stepProps}>
                <StepLabel className="text-white" {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, marginLeft: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: "block", flexDirection: "row", pt: 0 }}>
              {activeStep == 0 ? (
                <div>
                  <div className="container d-flex mobile_center p-0 ms-2">
                    <div className="row d-flex w-100">
                      <div className="col-lg-7">
                        <h2 className="text-white">Verify Token</h2>
                        <p className="mb-0">
                          Enter the token address and verify
                        </p>

                        <div className="forms">
                          <>
                            <div className="col-lg-12 mb-3 mt-3">
                              <div className="d-flex justify-content-between">
                                <label className="form-label">
                                  Contract Address{" "}
                                  <Tooltip
                                    title="Enter the Contract Address for your Token"
                                    arrow
                                  >
                                    <InfoOutlinedIcon className="info_icon" />
                                  </Tooltip>
                                </label>
                                <div className=" text-danger">
                                  Launchpad creation fee: {flatFee} BNB
                                </div>
                              </div>

                              <input
                                name="contractAdress"
                                value={formik.values.contractAdress}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  if (
                                    e.target.value == "" ||
                                    e.target.value == null ||
                                    e.target.value.length < 42
                                  ) {
                                    setTokenInfo({
                                      tokenName: "",
                                      tokenDecimal: 0,
                                      tokenSymbol: "",
                                      tokenBalance: 0,
                                    });
                                    setIsValid(true);
                                    setTokenStatus("conect & verify");
                                  }
                                }}
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter Token Address"
                              />

                              <button
                                className={`inp_btn ${tokenStatus == "conect & verify" ||
                                  !formik.values.contractAdress
                                  ? "bg-primary"
                                  : "bg-success"
                                  }`}
                                onClick={() => {
                                  checkAddress(formik);
                                }}
                                disabled={!formik.values.contractAdress}
                              >
                                {
                                  isVerified ? <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Loading...

                                  </> : <>
                                    {tokenStatus == "conect & verify" ||
                                      !formik.values.contractAdress
                                      ? "conect & verify"
                                      : "Verified"}
                                  </>
                                }

                              </button>
                            </div>
                            {formik.errors.contractAdress && (
                              <Form.Text className="text-danger">
                                {formik.errors.contractAdress}
                              </Form.Text>
                            )}
                          </>
                        </div>
                        <div className="currency mt-5 ">
                          <h3>
                            Choose Currency{" "}
                            <Tooltip
                              title="Select the preferred currency that your users will use to pay you"
                              arrow
                            >
                              <InfoOutlinedIcon className="info_icon" />
                            </Tooltip>
                          </h3>
                          <p className="mb-0">which users can pay you with</p>
                        </div>
                        <form className="mt-3">
                          <div className="row">
                            <div className="text-white p-3 justify-content-center aligin-items-center">
                              <div className="d-flex justify-content-between gap-3">
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currency"
                                    value="bnb"
                                    id="bnb"
                                    checked={formik.values.currency === "bnb"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      setFeeToken(
                                        "0x0000000000000000000000000000000000000000"
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bnb"
                                  >
                                    BNB
                                  </label>
                                </div>
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currency"
                                    id="vtt"
                                    value="vtt"
                                    checked={formik.values.currency === "vtt"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      setFeeToken(
                                        "0xa718bC2727bEB640E7200d620852cD83140c234C"
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="vtt"
                                  >
                                    VTT
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-3 gap-3">
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currency"
                                    id="usdt"
                                    value="usdt"
                                    checked={formik.values.currency === "usdt"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      setFeeToken(
                                        "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd"
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="usdt"
                                  >
                                    USDT
                                  </label>
                                </div>
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currency"
                                    id="busd"
                                    value="busd"
                                    checked={formik.values.currency === "busd"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      setFeeToken(
                                        "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee"
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="busd"
                                  >
                                    BUSD
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>

                        <h3 className="mt-2">
                          Listing Options{" "}
                          <Tooltip
                            title="Choose how you want your token to be listed: Automatically or Manually"
                            arrow
                          >
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </h3>
                        <div className="d-flex justify-content-between mt-3 gap-3">
                          <div className="form-check border_inp px-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="listing"
                              id="autolisting"
                              checked={formik.values.listing === "Auto Listing"}
                              value="Auto Listing"
                              onChange={(e) => formik.handleChange(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autolisting"
                            >
                              Auto Listing
                            </label>
                          </div>
                          <div className="form-check border_inp px-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="listing"
                              id="manuallisting"
                              checked={formik.values.listing === "Manual Listing"}

                              value="Manual Listing"
                              onChange={(e) => formik.handleChange(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="manuallisting"
                            >
                              Manual Listing
                            </label>
                          </div>
                        </div>
                        <h3 className="mt-3">
                          Fee Options{" "}
                          <Tooltip
                            title="Fees that will be deducted on finalization"
                            arrow
                          >
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </h3>
                        <form className="mt-3 w-100">
                          <div className="form-check border_inp_2  mt-3 mb-3">
                            <p className="m-0 text-white">
                              5% {formik.values.currency.toUpperCase()} raised
                              only
                            </p>
                          </div>
                        </form>
                        <div className="box p-3 d-flex">
                          <InfoOutlinedIcon className="info_icon text-white" />
                          <p className="ms-1">
                            Do not use this currency for auto liquidity tokens,
                            or tokens that depend on WETH pair. It will lead to
                            error when finalizing the pool or transfering the
                            tokens (for example Liquidity Generator Token,
                            BabyToken, Buyback Baby Token). Contact virtual team
                            for more information.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-5 col-12 d-none d-lg-block">
                        <div className="box">
                          <h5 className="mb-3">Token Info</h5>
                          <div className="d-flex justify-content-between">
                            <h6>Token Status</h6>
                            <p
                              className={`mb-1  px-2 rounded text-white  ${tokenStatus == "conect & verify" ||
                                !formik.values.contractAdress
                                ? "bg-primary"
                                : "bg-success"
                                }`}
                            >
                              {" "}
                              {tokenStatus == "conect & verify" ||
                                !formik.values.contractAdress
                                ? "conect & verify"
                                : "Verified"}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Token Name</h6>
                            <p className="mb-1">{tokenInfo?.tokenName}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Token Symbol</h6>
                            <p className="mb-1">{tokenInfo?.tokenSymbol}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Decimals</h6>
                            <p className="mb-1">{tokenInfo?.tokenDecimal}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Contract Address</h6>
                            <p className="mb-1">
                              {walletShortFormer(formik.values.contractAdress)}
                            </p>
                          </div>
                          <hr />
                          <h5 className="mb-3">Main Currency</h5>
                          <p className="">
                            Users can pay you with{" "}
                            <span className="text-uppercase">
                              {formik.values.currency}
                            </span>
                          </p>
                          <hr />
                          <h5 className="mb-3">Token Listing</h5>
                          <div className="d-flex justify-content-between">
                            <p>List Options</p>
                            <p className="mb-1 text-white">
                              {formik.values.listing}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Launchpad Fee Options</p>
                            <p className="mb-1 text-white">
                              5% {formik.values.currency.toUpperCase()} raised
                              only
                            </p>
                          </div>
                          <hr />
                          {/* <h5 className="mb-1">Create token easily now</h5>
                        <p>A quick tutorial from our experts to create a token.</p> */}
                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            muted
                          >
                            <source
                              src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className={"" + (activeStep === 0 ? "d-none" : "back_btn")}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    className="btn_next style_next mb-2 text-white"
                    disabled={Boolean(formik.errors.contractAdress) || isValid}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              ) : activeStep == 1 ? (
                <div>
                  <div className="container d-flex mobile_center p-0 ms-2">
                    <div className="row d-flex w-100">
                      <div className="col-lg-7">
                        <h2 className="text-white">DeFi Launchpad Info</h2>
                        <p className="mb-0">
                          Enter the launchpad information that you want to raise
                          , enter all details about your presale
                        </p>

                        <div className="forms">
                          <>
                            <div className="col-lg-12 mb-3 mt-3">
                              <label className="form-label">
                                Presale Rate{" "}
                                <Tooltip
                                  title="Enter the number of tokens an investors would get foe each 1 unit of currency they invest "
                                  arrow
                                >
                                  <InfoOutlinedIcon className="info_icon" />
                                </Tooltip>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="100"
                                name="presaleRate"
                                value={formik.values.presaleRate}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                              />
                              {formik.errors.presaleRate && (
                                <Form.Text className="text-danger">
                                  {formik.errors.presaleRate}
                                </Form.Text>
                              )}
                            </div>
                          </>
                        </div>
                        <div className="currency mt-5 ">
                          <h3>
                            Whitelist{" "}
                            <Tooltip
                              title="It is a pre-approved list of contributors with exclusive access to particpate in the presale stage"
                              arrow
                            >
                              <InfoOutlinedIcon className="info_icon" />
                            </Tooltip>
                          </h3>
                        </div>
                        <div className="">
                          {
                            launchpadType == 'Create launchpad' ? <>
                              <div className="row">
                                <div className="text-white p-3 justify-content-center aligin-items-center">
                                  <div className="d-flex justify-content-between gap-3">
                                    <div className="form-check border_inp px-5">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="whitelist"
                                        value="disable"
                                        checked={formik.values.whitelist === "disable"}

                                        onChange={(e) => {
                                          formik.handleChange(e);
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="disable"
                                      >
                                        Disable
                                      </label>
                                    </div>
                                    <div className="form-check border_inp px-5">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="whitelist"
                                        value="enable"
                                        checked={formik.values.whitelist === "enable"}

                                        onChange={(e) => {
                                          formik.handleChange(e);
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="enable"
                                      >
                                        Enable
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                              :
                              <>
                                <Button className="btn_next style_next mb-2 text-white" onClick={() => { setShowUserAddWhitelist(true) }}>
                                  create whitelist user
                                </Button>
                                <AddUserToWhitelistPrivateSale addresess={addresses} showUserAddWhitelist={showUserAddWhitelist} setShowUserAddWhitelist={setShowUserAddWhitelist} setAddresses={setAddresses} />
                                {/* <form onSubmit={handleSubmits}>
                                  <textarea onChange={handleInputChange} />
                                  <button type="submit">Submit</button>
                                </form> */}
                              </>
                          }
                        </div>
                        <h3 className="mt-3">
                          Launchpad Details{" "}
                          <Tooltip
                            title="Tell us more about your launchpad"
                            arrow
                          >
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </h3>

                        <div className="row ">
                          <div className="forms">
                            <div className="d-flex justify-content-between gap-5">
                              <div className="col-lg-4 mb-3 mt-3 d-dlex">
                                <label className="form-label">
                                  Softcap {formik.values.currency.toUpperCase()}{" "}
                                  <Tooltip
                                    title="Softcap is the minimum amount of funds required for the project to proceed"
                                    arrow
                                  >
                                    <InfoOutlinedIcon className="info_icon" />
                                  </Tooltip>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Amount"
                                  name="softcap"
                                  value={formValues.softcap}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                                {formErrors.softcap && (
                                  <Form.Text className="text-danger">
                                    {formErrors.softcap}
                                  </Form.Text>
                                )}
                              </div>
                              <div className="col-lg-4 mb-3 mt-3">
                                <label className="form-label">
                                  Hardcap {formik.values.currency.toUpperCase()}{" "}
                                  <Tooltip
                                    title="Hardcap is the maximum amount of funds that the project can raise."
                                    arrow
                                  >
                                    <InfoOutlinedIcon className="info_icon" />
                                  </Tooltip>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Amount"
                                  name="hardcap"
                                  value={formValues.hardcap}
                                  onChange={async (e) => {
                                    handleChange(e);
                                  }}
                                />
                                {formErrors.hardcap && (
                                  <Form.Text className="text-danger">
                                    {formErrors.hardcap}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between gap-5">
                              <div className="col-lg-4 mb-3 mt-3 d-dlex">
                                <label className="form-label">
                                  Minimum Buy{" "}
                                  {formik.values.currency.toUpperCase()}
                                  <Tooltip
                                    title="Enter the minimum amount of bnb required to participate in your presale"
                                    arrow
                                  >
                                    <InfoOutlinedIcon className="info_icon" />
                                  </Tooltip>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Amount"
                                  name="minimumBuy"
                                  value={formik.values.minimumBuy}
                                  onChange={async (e) => {
                                    minimumbuy(e, formik);
                                  }}
                                />
                                {formik.errors.minimumBuy && (
                                  <Form.Text className="text-danger">
                                    {formik.errors.minimumBuy}
                                  </Form.Text>
                                )}
                              </div>
                              <div className="col-lg-4 mb-3 mt-3">
                                <label className="form-label">
                                  Maximum Buy{" "}
                                  {formik.values.currency.toUpperCase()}
                                  <Tooltip
                                    title="Enter the maximum amount of bnb required to participate in your presale"
                                    arrow
                                  >
                                    <InfoOutlinedIcon className="info_icon" />
                                  </Tooltip>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Amount"
                                  name="maximumBuy"
                                  value={formik.values.maximumBuy}
                                  onChange={(e) => {
                                    maximumbuy(e, formik);
                                  }}
                                />
                                {formik.errors.maximumBuy && (
                                  <Form.Text className="text-danger">
                                    {formik.errors.maximumBuy}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3 className="mt-3">
                          Refund Type{" "}
                          <Tooltip title="This is refund type" arrow>
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </h3>
                        <p className="mb-0">
                          Choose the type of refund at the end
                        </p>
                        <form className="">
                          <div className="row">
                            <div className="text-white p-3 justify-content-center aligin-items-center">
                              <div className="d-flex justify-content-between gap-3">
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="refundType"
                                    value="0"
                                    checked={formik.values.refundType === "0"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="disable"
                                  >
                                    Burn
                                  </label>
                                </div>
                                <div className="form-check border_inp px-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="refundType"
                                    value="1"
                                    checked={formik.values.refundType === "1"}

                                    onChange={(e) => {
                                      formik.handleChange(e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="enable"
                                  >
                                    Refund
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <hr />
                        {
                          formik.values.listing === 'Auto Listing' &&
                          <>
                            <h5 className="mt-3">
                              Liquidity Info{" "}
                              <Tooltip
                                title="Enter the details of how you plan to provide liquidity for your token"
                                arrow
                              >
                                <InfoOutlinedIcon className="info_icon" />
                              </Tooltip>
                            </h5>
                            <h3 className="mb-0 mt-3">
                              Router Type{" "}
                              <Tooltip
                                title="These decentralized exchange (DEX) platforms allow users to trade their tokens without the need for an intermediary."
                                arrow
                              >
                                <InfoOutlinedIcon className="info_icon" />
                              </Tooltip>
                            </h3>
                            <p className="mb-0">
                              Choose the type of router platform to use
                            </p>

                            <div className="row">
                              <div className="text-white py-3 justify-content-center aligin-items-center">
                                <div className="d-flex justify-content-between">
                                  <div className="form-check border_inp px-5 w-100">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="routerType"
                                      value="virtualRouter"
                                      defaultChecked={true}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="virtualRouter"
                                    >
                                      Virtual Router
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-between">
                              <div className="forms">
                                <form className="d-flex justify-content-between gap-5">
                                  <div className="col-lg-4 mb-3 mt-3 d-dlex">
                                    <label className="form-label">
                                      Router Liquidity(%){" "}
                                      <Tooltip
                                        title="Enter the % of raised funds that should be allocated to liquidity on DEX. Minimum value is 51%, maximum value is 100%."
                                        arrow
                                      >
                                        <InfoOutlinedIcon className="info_icon" />
                                      </Tooltip>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Amount"
                                      name="routerLiquidity"
                                      value={formik.values.routerLiquidity}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                      }}
                                    />
                                    {formik.errors.routerLiquidity && (
                                      <Form.Text className="text-danger">
                                        {formik.errors.routerLiquidity}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div className="col-lg-4 mb-3 mt-3">
                                    <label className="form-label">
                                      Router Listing Rate{" "}
                                      <Tooltip
                                        title="It is the initial rate of the liquidity pool (1 BNB = x tokens)"
                                        arrow
                                      >
                                        <InfoOutlinedIcon className="info_icon" />
                                      </Tooltip>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Amount"
                                      name="routerListingRate"
                                      value={formik.values.routerListingRate}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                      }}
                                    />
                                    {formik.errors.routerListingRate && (
                                      <Form.Text className="text-danger">
                                        {formik.errors.routerListingRate}
                                      </Form.Text>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </>
                        }

                      </div>

                      <div className="col-lg-5 col-12 d-none d-lg-block">
                        <div className="box">
                          <h5 className="mb-3">DeFi Launchpad Info</h5>
                          <div className="d-flex justify-content-between">
                            <h6>Presale Rate</h6>
                            <p className="mb-1">{formik.values.presaleRate}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Whitelist</h6>
                            <p className="mb-1">{formik.values.whitelist}</p>
                          </div>
                          <hr />
                          <h5 className="mb-3">LaunchPad Details</h5>
                          <div className="d-flex justify-content-between">
                            <h6>
                              Softcap {formik.values.currency.toUpperCase()}
                            </h6>
                            <p className="mb-1">{formValues.softcap}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>
                              Hardcap {formik.values.currency.toUpperCase()}
                            </h6>
                            <p className="mb-1">{formValues.hardcap}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>
                              Minimum Buy {formik.values.currency.toUpperCase()}
                            </h6>
                            <p className="mb-1">{formik.values.minimumBuy}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>
                              Maximum Buy {formik.values.currency.toUpperCase()}
                            </h6>
                            <p className="mb-1">{formik.values.maximumBuy}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Refund Type</h6>
                            <p className="mb-1">
                              {formik.values.refundType == "0"
                                ? "Burn"
                                : "Refund"}
                            </p>
                          </div>
                          <hr />
                          <h5 className="mb-3">Liquidity Info</h5>
                          <div className="d-flex justify-content-between">
                            <p>Router Type</p>
                            <p className="mb-1 text-white">
                              {formik.values.routerType}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Router Liquidity(%)</p>
                            <p className="mb-1 text-white">
                              {formik.values.routerLiquidity}%{" "}
                              {formik.values.currency.toUpperCase()} Raised Only
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p> Router Listing Rate</p>
                            <p className="mb-1 text-white">
                              {formik.values.routerListingRate}
                            </p>
                          </div>

                          <hr />
                          {/* <h5 className="">Required Tokens</h5>
                          <div className=" ">
                            <p>Need 257 Jwd tokens to create launchpad</p>
                          </div> */}
                          {/* <h5 className="mb-1">Create token easily now</h5>
                        <p>A quick tutorial from our experts to create a token.</p> */}
                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            muted
                          >
                            <source
                              src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    color="inherit"
                    disabled={!(activeStep == 1)}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className={
                      "" + (!(activeStep == 1) ? "d-none" : "back_btn")
                    }
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      handleNext();
                      calculateToken();
                    }}
                    className="btn_next style_next mb-2 text-white"
                    disabled={
                      Boolean(formErrors.softcap) ||
                      Boolean(formErrors.hardcap) ||
                      Boolean(formik.errors.maximumBuy) ||
                      Boolean(formik.errors.minimumBuy) ||
                      (formik.values.listing === 'Auto Listing' && (
                        Boolean(formik.errors.presaleRate) ||
                        Boolean(formik.errors.routerLiquidity) ||
                        Boolean(formik.errors.routerListingRate)
                      ))
                    }
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              ) : activeStep == 2 ? (
                <div>
                  <div className="container d-flex mobile_center p-0 ms-2">
                    <div className="row d-flex w-100">
                      <div className="col-lg-7">
                        <h2 className="text-white">Time & Duration</h2>
                        <p className="mb-0">
                          Enter the launchpad information that you want to raise
                          , enter all details about your presale
                        </p>

                        <form className="mt-3">
                          <div className="row">
                            <div className="text-white p-3 justify-content-center aligin-items-center">
                              <div className="d-flex justify-content-between gap-2">
                                <div className="col-lg-6">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <div className="text-start for_fnt">
                                      <Form.Label>Start Time (UTC)</Form.Label>
                                    </div>
                                    <Form.Control
                                      type="datetime-local"
                                      name="startTime"
                                      className="input input_flied_of_pink bg_inp"
                                      placeholder="Select date"
                                      autoComplete="on"
                                      onChange={formik.handleChange}
                                      value={formik.values.startTime}
                                    />
                                    <div className="text-start">
                                      {formik.errors.startTime && (
                                        <Form.Text className="text-danger">
                                          {formik.errors.startTime}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </Form.Group>
                                </div>

                                <div className="col-lg-6">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <div className="text-start for_fnt">
                                      <Form.Label>End Time (UTC)</Form.Label>
                                    </div>
                                    <Form.Control
                                      type="datetime-local"
                                      className="input input_flied_of_pink bg_inp"
                                      placeholder="Select date"
                                      autoComplete="on"
                                      name="endTime"
                                      onChange={formik.handleChange}
                                      value={formik.values.endTime}
                                    />
                                    <div className="text-start">
                                      {formik.errors.endTime && (
                                        <Form.Text className="text-danger">
                                          {formik.errors.endTime}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            {formik.values.listing == 'Auto Listing' &&
                              <>
                                <div className="col-lg-12 mb-3 mt-3">
                                  <label className="form-label">
                                    Liquidity Lockup Minutes{" "}
                                    <Tooltip title="Enter the lockup minutes" arrow>
                                      <InfoOutlinedIcon className="info_icon" />
                                    </Tooltip>
                                  </label>

                                  <input
                                    name="lockupMinutes"
                                    value={formik.values.lockupMinutes}
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter lockup minutes"
                                    onChange={(e) => formik.handleChange(e)}
                                  />
                                </div>
                                {formik.errors.lockupMinutes && (
                                  <Form.Text className="text-danger">
                                    {formik.errors.lockupMinutes}
                                  </Form.Text>
                                )}
                              </>
                            }

                          </div>
                        </form>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={() => setVesting(!vesting)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Using Vesting Contributor ?{" "}
                            <Tooltip
                              title="It promotes a project's long-term price stability by locking presale investors'tokens for a specific period, preventing excessive sell pressure during listings."
                              arrow
                            >
                              <InfoOutlinedIcon className="info_icon" />
                            </Tooltip>
                          </label>
                        </div>
                        {vesting && (
                          <>
                            <div className="forms">
                              <form>
                                <div className="col-lg-12 mb-3 mt-3">
                                  <label className="form-label">
                                    First Release For Presale (Percent){" "}
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title="The percentage of the first batch of the private sale fund to be released to the project owner. Please note this is expressed in percentage and cannot be greater than 95%"
                                    >
                                      <InfoOutlinedIcon className="info_icon" />
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Amount"
                                    name="firstReleasePresale"
                                    value={formik.values.firstReleasePresale}
                                    onChange={(e) => handlepercentage(e, formik)}
                                  />
                                  <div className="text-start">
                                    {formik.errors.firstReleasePresale && (
                                      <Form.Text className="text-danger">
                                        {formik.errors.firstReleasePresale}
                                      </Form.Text>
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>

                            <h3 className="mb-0">Each Cycle </h3>
                            <div className="d-flex justify-content-between ">
                              <div className="forms">
                                <form className="d-flex justify-content-between gap-2">
                                  <div className="col-lg-8 mb-3 mt-3 d-dlex">
                                    <label className="form-label">
                                      Presale Token Release (Percent){" "}
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Presale token release percent"
                                      >
                                        <InfoOutlinedIcon className="info_icon" />
                                      </span>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Amount"
                                      name="presaleTokenPercent"
                                      value={formik.values.presaleTokenPercent}
                                      onChange={(e) => { handlepercentage(e, formik) }}
                                    />
                                    <div className="text-start">
                                      {formik.errors.presaleTokenPercent && (
                                        <Form.Text className="text-danger">
                                          {formik.errors.presaleTokenPercent}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-8 mb-3 mt-3">
                                    <label className="form-label">
                                      Vesting Period (Minutes){" "}
                                      <Tooltip
                                        title="Measured in days, determines the duration between the release of batches of funds from the private sale."
                                        arrow
                                      >
                                        <InfoOutlinedIcon className="info_icon" />
                                      </Tooltip>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Minutes"
                                      name="vestingPeriod"
                                      value={formik.values.vestingPeriod}
                                      onChange={(e) => formik.handleChange(e)}
                                    />
                                    <div className="text-start">
                                      {formik.errors.vestingPeriod && (
                                        <Form.Text className="text-danger">
                                          {formik.errors.vestingPeriod}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="box p-3">
                              <p className="text-white">
                                Enter the percentage of raised funds that should
                                be allocated to Liquidity on (Min 51% Max 100%)
                                If I spend 1BNB on how many tokens will I
                                receive? Usually this amount is lower than
                                presale rate to allow for a higher listing price
                                on
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-lg-5 col-12 d-none d-lg-block">
                        <div className="box">
                          <h5 className="mb-3">Time & Duration</h5>
                          <div className="d-flex justify-content-between">
                            <h6>Start Date</h6>
                            <p className="mb-1 text-white">
                              {moment(formik.values.startTime).format('LLLL')}

                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Liquidity Lockup (Days)</h6>
                            <p className="mb-1 text-white">
                              {formik.values.lockupMinutes} Minutes
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>End Date</h6>
                            <p className="mb-1 text-white">
                              {moment(formik.values.endTime).format('LLLL')}

                            </p>
                          </div>
                          <hr />
                          <h5 className="mb-3">Using Vesting Contributor ?</h5>
                          <div className="d-flex justify-content-between">
                            <h6>First Release For Presale</h6>
                            <p className="mb-1">
                              {formik.values.firstReleasePresale} %
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Vesting Period Each Cycle</h6>
                            <p className="mb-1">
                              {formik.values.vestingPeriod} Minutes
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Presale Token Release Each Cycle</h6>
                            <p className="mb-1">
                              {formik.values.presaleTokenPercent} %
                            </p>
                          </div>
                          <hr />

                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            muted
                          >
                            <source
                              src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    color="inherit"
                    disabled={!(activeStep == 2)}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className={
                      "" + (!(activeStep == 2) ? "d-none" : "back_btn")
                    }
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      handleNext();
                      calculateToken();
                    }}
                    className="btn_next style_next mb-2 text-white"
                    disabled={
                      (formik.values.listing === 'Auto Listing' && (Boolean(formik.errors.lockupMinutes))) ||
                      Boolean(formik.errors.endTime) ||
                      Boolean(formik.errors.startTime) ||

                      (vesting == true &&
                        (Boolean(formik.errors.firstReleasePresale) ||
                          Boolean(formik.errors.presaleTokenPercent) ||
                          Boolean(formik.errors.vestingPeriod)))
                    }
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              ) : activeStep == 3 ? (
                // <AdditionalInfo />
                <div className="container d-flex mobile_center p-0 ms-2">
                  <div className="row d-flex w-100">
                    <div className="col-lg-7">
                      <h2 className="text-white">Add Logo</h2>
                      <p className="mb-0">
                        URL must end with a supported image extension png, jpg,
                        jpeg or gif. Use our “Create Logo URL” to generate a URL
                        link for you.
                      </p>
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          Logo Pic URL{" "}
                          <Tooltip title='You may either provide the URL of your logo image or utilize our "Create logo URl" feature to generate a URL link for your logo" arrow'>
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </label>
                        <input
                          type="url"
                          className="form-control py-1 mb-3"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="logo Url"
                          name="logoUrl"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          value={formik.values.logoUrl}
                        />
                        <div className="text-start">
                          {formik.errors.logoUrl && (
                            <Form.Text className="text-danger">
                              {formik.errors.logoUrl}
                            </Form.Text>
                          )}
                        </div>

                        {/* 👇 Notice the `display: hidden` on the input */}

                        <Button
                          variant="outlined"
                          component="label"
                          onClick={handleUploadClick}
                        >
                          {createLogoLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </>
                          ) : (
                            <>Create Logo URL</>
                          )}
                        </Button>
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          ref={inputRef}
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="currency mt-5 ">
                        <h3>Add Description</h3>
                        <p className="mb-0">
                          Let users know more about your project by adding
                          detailed info about your token.
                        </p>
                        <h3 className="mt-3">
                          Enter Token Description{" "}
                          <Tooltip title="Add a comprehensive token discription to help your users understand your prjoct better.">
                            <InfoOutlinedIcon className="info_icon" />
                          </Tooltip>
                        </h3>
                        <TextareaAutosize
                          aria-label="empty textarea"
                          placeholder="Enter Discription"
                          value={formik.values.discription}
                          onChange={(e) => formik.handleChange(e)}
                          name="discription"
                          minRows={5}
                          style={{
                            width: 700,
                            backgroundColor: "black",
                            color: "white",
                          }}
                        />
                        ;
                      </div>

                      <h3 className="mt-5 mt-lg-3">Social Links </h3>
                      <p className="mb-0">
                        Provide your socials as each link will display on your
                        launchpad page. Viewers will be able to visit your
                        links.
                      </p>

                      <hr />
                      <h3 className="mt-3">Add Social Link </h3>

                      <p className="mb-0">
                        You must provide a website and a twitter account of your
                        token so users can know more about your project.
                        Otherwise, it is optional to add more social links
                      </p>

                      <div className="d-flex justify-content-between mt-3">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Website</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Website Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="websiteUrl"
                            value={formik.values.websiteUrl}
                          />
                          <div className="text-start">
                            {formik.errors.websiteUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.websiteUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Facebook</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Facebook Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="facebookUrl"
                            value={formik.values.facebookUrl}
                          />
                          <div className="text-start">
                            {formik.errors.facebookUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.facebookUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Twitter</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="User name"
                            onChange={(e) => formik.handleChange(e)}
                            name="twitterUrl"
                            value={formik.values.twitterUrl}
                          />
                          <div className="text-start">
                            {formik.errors.twitterUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.twitterUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Github</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Github Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="githubUrl"
                            value={formik.values.githubUrl}
                          />
                          <div className="text-start">
                            {formik.errors.githubUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.githubUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Telegram</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="telegram Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="telegramUrl"
                            value={formik.values.telegramUrl}
                          />
                          <div className="text-start">
                            {formik.errors.telegramUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.telegramUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Instagram</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="instagram Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="instagramUrl"
                            value={formik.values.instagramUrl}
                          />
                          <div className="text-start">
                            {formik.errors.instagramUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.instagramUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Discord</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="discord Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="discordUrl"
                            value={formik.values.discordUrl}
                          />
                          <div className="text-start">
                            {formik.errors.discordUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.discordUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-2">
                          <p className="px-3 py-1 p_bor">Reddit</p>
                        </div>
                        <div className="col-lg-9">
                          <input
                            type="url"
                            className="form-control py-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="reddit Url"
                            onChange={(e) => formik.handleChange(e)}
                            name="redditUrl"
                            value={formik.values.redditUrl}
                          />
                          <div className="text-start">
                            {formik.errors.redditUrl && (
                              <Form.Text className="text-danger">
                                {formik.errors.redditUrl}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <Button
                        color="inherit"
                        disabled={!(activeStep == 3)}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className={
                          "" + (!(activeStep == 3) ? "d-none" : "back_btn")
                        }
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleNext}
                        className="btn_next style_next mb-2 text-white"
                        disabled={
                          Boolean(formik.errors.logoUrl) ||
                          Boolean(formik.errors.websiteUrl)
                        }
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>

                    <div className="col-lg-5 col-12 d-none d-lg-block">
                      <div className="box">
                        <h5 className="mb-3">Token Logo</h5>
                        <div className="box-2 d-flex justify-content-center">
                          {formik.values.logoUrl && (
                            <div className=" ">
                              <img
                                src={formik.values.logoUrl}
                                className="rounded-circle"
                                height={64}
                                width={64}
                              />
                            </div>
                          )}
                          {!formik.values.logoUrl && (
                            <div className=" ">
                              <img
                                src={tokenLogo}
                                className="rounded-circle"
                                height={64}
                                width={64}
                              />
                            </div>
                          )}

                          <div className="d-block ms-3 mt-2">
                            <div className="profile_Name text-white">
                              {tokenInfo.tokenName}
                            </div>
                            <div className="nick_name text-white">
                              {tokenInfo.tokenSymbol}
                            </div>
                          </div>
                        </div>
                        <hr />


                        <video
                          width="100%"
                          height="100%"
                          controls
                          autoPlay
                          muted
                        >
                          <source
                            src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeStep == 4 ? (
                <>
                  <div className="container d-flex mobile_center">
                    <div className="row d-flex w-100">
                      <div className="col-lg-7">
                        <h2 className="text-white">Review & Publish</h2>
                        <p className="mb-0">
                          Confirm your Launchpad details and publish it.
                        </p>
                        <div className="box-2 p-3 mt-2 mb-4">
                          <h3>Token Info</h3>
                          <div className="row">
                            <div className="col-lg-4">
                              {formik.values.logoUrl && (
                                <div className=" ">
                                  <img
                                    src={formik.values.logoUrl}
                                    className="rounded-circle"
                                    height={90}
                                    width={90}
                                  />
                                </div>
                              )}
                              {!formik.values.logoUrl && (
                                <div className=" ">
                                  <img
                                    src={tokenLogo}
                                    className="rounded-circle"
                                    height={90}
                                    width={90}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-lg-8">
                              <div className="d-flex justify-content-between mt-3">
                                <div className="d-block">
                                  <h3>Token Name</h3>
                                  <p>{tokenInfo.tokenName}</p>
                                </div>
                                <div className="d-block me-4">
                                  <h3>Token Symbol</h3>
                                  <p>{tokenInfo.tokenSymbol}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4"></div>
                            <div className="col-lg-8">
                              <div className="d-flex justify-content-between">
                                <div className="d-block">
                                  <h3>Deciemals</h3>
                                  <p>{tokenInfo.tokenDecimal}</p>
                                </div>
                                <div className="d-block">
                                  <h3>Contract Address</h3>
                                  <p>
                                    {walletShortFormer(
                                      formik.values.contractAdress
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="px-3">
                            <div className="d-flex justify-content-between">
                              <p>Users Can Pay You With</p>
                              <h3 className="text-uppercase">
                                {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Listing Options</p>
                              <h3>{formik.values.listing}</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>virtual Fee Options</p>
                              <h3>
                                5%{" "}
                                <span className="text-uppercase">
                                  {formik.values.currency}
                                </span>{" "}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="box-2 p-3 mt-2 mb-4">
                          <div className="px-3">
                            <h5>Virtual Launchpad Info</h5>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Presale Rate</p>
                              <h3 className="text-uppercase">
                                {formik.values.presaleRate}{" "}
                                {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Whitelist</p>
                              <h3 className="text-capitalize">
                                {formik.values.whitelist}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Softcap</p>
                              <h3 className="text-uppercase">
                                {formValues.softcap} {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Hardcap </p>
                              <h3 className="text-uppercase">
                                {formValues.hardcap} {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Minimum Buy </p>
                              <h3 className="text-uppercase">
                                {toPlainString(formik.values.minimumBuy)}{" "}
                                {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Maximum Buy </p>
                              <h3 className="text-uppercase">
                                {toPlainString(formik.values.maximumBuy)}{" "}
                                {formik.values.currency}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Unsold Tokens </p>
                              <h3 className="">
                                {formik.values.refundType == '0' ? 'Burn' : 'Refund'}

                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Liquidity </p>
                              <h3 className="">
                                {formik.values.routerLiquidity} %

                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Listing Rate </p>
                              <h3 className="">
                                {formik.values.routerListingRate} {tokenInfo.tokenSymbol}

                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Website </p>
                              <p className="text-white text-center ms-5 w-75" style={{ fontSize: '15px',overflow:"hidden",overflowWrap:"anywhere" }}>
                                {formik.values.websiteUrl}

                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="box-2 p-3 mt-2 mb-4">
                          <div className="px-3">
                            <h5>Time & Duration</h5>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Start Date</p>
                              <h3>{moment(formik.values.startTime).format('LLLL')}</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">End Date</p>
                              <h3>{moment(formik.values.endTime).format('LLLL')}</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Liquidity Lockup (Minutes)</p>
                              <h3>{formik.values.lockupMinutes} Minutes</h3>
                            </div>
                            <h5>Using Vesting Contributor ?</h5>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">First Release For Presale</p>
                              <h3>{formik.values.firstReleasePresale} %</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Vesting Period Each Cycle</p>
                              <h3>{formik.values.vestingPeriod} Minutes</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">
                                Presale Token Release Each Cycle
                              </p>
                              <h3>{formik.values.presaleTokenPercent} %</h3>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          {isApproved &&
                            <Button
                              className="btn_next style_next text-white "
                              onClick={() => {
                                handleApprove();
                              }}
                            >
                              {isApprovedLoadding ? (
                                <>
                                  {" "}
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Loading...
                                </>
                              ) : (
                                <>
                                  {" "}
                                  Approve {toPlainString(Number(requiredTokens) / 10 ** 18)}{" "}
                                  {tokenInfo?.tokenSymbol} Tokens
                                </>
                              )}
                            </Button>
                          }

                        </div>
                        <Button
                          color="inherit"
                          disabled={!(activeStep == 4)}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          className={
                            "" + (!(activeStep == 4) ? "d-none" : "back_btn")
                          }
                        >
                          Back
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          disabled={isApproved}
                          className="btn_next style_next mb-2 text-white"
                        >
                          {activeStep === steps.length - 1 ? "Create Launchpad" : "Next"}
                        </Button>
                      </div>

                      <div className="col-lg-5 col-12 d-none d-lg-block">
                        <div className="box">
                          <h5 className="mb-3">Payment Details</h5>
                          <div className="d-flex justify-content-between">
                            <h6>Launchpad Creation Fee</h6>
                            <p className="mb-1 text-white">{flatFee} BNB</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6>Additional Fees</h6>
                            <p className="mb-1">-</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <h6>Total</h6>
                            <p className="mb-1 text-white">{flatFee} BNB</p>
                          </div>

                          <hr />
                          <h5 className="mb-1">Required Tokens</h5>
                          <p className="text-white">
                            Need {Number(requiredTokens) / 10 ** 18}
                            {` `}
                            {tokenInfo?.tokenSymbol} Tokens To Create Launchpad
                          </p>
                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            muted
                          >
                            <source
                              src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
