import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { getWeb3, loadWeb3 } from "../../connectivity/connectivity";
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import { useSelector } from "react-redux";


const ClaimTime = (props: any) => {
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  const createLaunchpadSchema = Yup.object().shape({
    claimTime: Yup.date()
      .required("Must Enter Start time")
      .min(new Date(), "date must be greater then current date"),


  });
  const formik = useFormik({
    initialValues: {
      claimTime: Date.now(),
    },
    validationSchema: createLaunchpadSchema,
    onSubmit: async (values, action) => {
      // await createLaunchpad(values);
      // action.resetForm();
    },
  });

  const claimTime = async (time: any) => {
    console.log('time', time)
    console.log(' props.userAddress', props.userAddress)

    try {
      let web3 = await getWeb3()

      let launchpadInstance = await new web3.eth.Contract(
        launchpadContractAbi,
        props.userAddress
      )
      let updateClaimTimetxn = await launchpadInstance.methods.setClaimTime(time).send({
        from: btnTxt
      })
      props.setShowClaimTime(false)
      props.getInfo()

    } catch (error) {
      console.error('error while set claim time', error)
    }
  }

  return <div>

    <Modal className="" show={props.showClaimTime} onHide={() => props.setShowClaimTime(false)} centered>
      <Modal.Header closeButton >
        <Modal.Title>Set Claim Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="claimTime">Claim Time</label>
            <input type="datetime-local" className="form-control text-dark" placeholder="Enter Claim Time"
              autoComplete="on"
              name="claimTime"

              onChange={formik.handleChange}
              value={formik.values.claimTime}
            />
          </div>

          <div className="text-start">
            {formik.errors.claimTime && (
              <Form.Text className="text-danger">
                {formik.errors.claimTime}
              </Form.Text>
            )}
          </div>

        </div>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => claimTime(Math.floor(Date.now() / 1000))}>
          Claim Right Now
        </Button>
        <Button variant="success" disabled={(Boolean(formik.errors.claimTime))} onClick={() => claimTime(Math.floor(
          new Date(formik.values.claimTime).getTime() / 1000
        ))}>
          Set Claim Time
        </Button>

      </Modal.Footer>
    </Modal>
  </div>;
};

export default ClaimTime;
