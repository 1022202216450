import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Contributors = (props: any) => {
  return <div>

    <Modal size="lg" className="bg-dark" show={props.showContributors} centered>
      <Modal.Header closeButton onClick={() => props.handleCloseContributors()}>
        <Modal.Title>Contributors</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table align-middle">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Address({props.listOfContributors.length})</th>
              <th scope="col">Amount</th>

            </tr>
          </thead>
          <tbody>
            {
              props.listOfContributors.map((list:any, index:number) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{list.address}</td>
                    <td>{list.contribution}</td>

                  </tr>
                )
              })
            }



          </tbody>
        </table>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => props.handleCloseContributors()}>
          ok
        </Button>

      </Modal.Footer>
    </Modal>
  </div>;
};

export default Contributors;
