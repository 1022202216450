import bg_profile from "../../assets/bg_profile.jpg";
import EditIcon from "@mui/icons-material/Edit";
import WalletIcon from "@mui/icons-material/Wallet";
import "./profile.css";
import BasicTabs from "./ProfileTabs";
import { Link } from "react-router-dom";
const Profile = ({ open }: { open: Boolean }) => {
  return (
    <>
      <div
        className={
          "mt-5 ms-0 ms-lg-5" +
          (open ? " margin-side-open" : " margin-side-close")
        }
      >
        <div className="container-fluid p-0">
          <div className="row w-100 justify-content-center align-items-center mob_mar">
            <div className="col-lg-12 col-12">
              <img src={bg_profile} alt="" height={300} width={"100%"} />
            </div>
            <div className="col-lg-11 col-11  bg-dark margin_rev rounded_bor p-3">
              <div className="d-flex">
                <div className="profile_pic">
                  <img
                    className="rounded_pic"
                    src={bg_profile}
                    alt=""
                    height={60}
                    width={60}
                  />
                </div>
                <div className="profile_name ms-3">
                  <h5>Usman</h5>
                  <p>USI</p>
                </div>
                <Link to="/editprofile" className="ms-auto">
                  <button className="text-end edit_profile">
                    <EditIcon className="me-2" />
                    Edit Profile
                  </button>
                </Link>
              </div>
              <div className="token_address">
                <p className="mb-1">Joined. Apr 4, 2023</p>
                <p>
                  <WalletIcon className="me-2" />
                  0xc2e3addd10f6c48082ae6d8
                </p>
              </div>
              <BasicTabs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
