import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./sale.css";
import { NavLink } from "react-router-dom";
import LaunchPadFilter from "../LaunchPad/LaunchPadFilter";
import PrivateDashboardpage from "../../Pages/DashboardPage/PrivateDashboardpage";

const PrivateSale = ({ open }: { open: Boolean }) => {
  const [activeFilter, setActiveFilter] = useState("one");
  return (
    <div className='w-100 flex-wrap p-1 p-lg-0'>
      <LaunchPadFilter open={open} launchpadTitle='Create private Sale' />
      <PrivateDashboardpage open={open} />
      {/* <LaunchPadCards open={open} buttonTitle={'Participate'} /> */}
    </div>
  )
};

export default PrivateSale;
