import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from "react-redux";

import createLaunchpadSlice from './feature/createLaunchpadSlice'
import launchpadDataSlice from './feature/launchpadDataSlice'
import PrivatelaunchpadDataSlice from './feature/PrivatelaunchpadDataSlice'

export const store = configureStore({
  reducer: {
    createLaunchpadSlice:createLaunchpadSlice,
    launchpadData:launchpadDataSlice,
    PrivatelaunchpadData:PrivatelaunchpadDataSlice,
  },
})
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>