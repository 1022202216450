import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import CreateLaunchPadPage from "./Pages/CreateLaunchPage.tsx/CreateLaunchPadPage";
import LaunchPadPage from "./Pages/LaunchPadPage.tsx/LaunchPadPage";
import TopNav from "./Components/Navbar/TopNav";
import Navbar from "./Components/Navbar/Navbar";
import MobileNavbar from "./Components/Navbar/MobileNavbar";
import LaunchPadCards from "./Components/LaunchPad/LaunchPadCards";
import PrivateSale from "./Components/PrivateSale/PrivateSale";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import ProfileEdit from "./Pages/ProfilePage/ProfileEdit";
import Dashboardpage from "./Pages/DashboardPage/Dashboardpage";
import ContriView from "./Components/DashboardCards.tsx/ContriView";
import 'react-loading-skeleton/dist/skeleton.css'
import Edit from './Components/EditLaunchpad/EditLaunchpad'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PrivateView from "./Components/DashboardCards.tsx/PrivateView";
import Landing from "./Pages/LandingPages/Landing";
import Price from "./Pages/Price/Price";
function App() {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="App">
      <div className="bg_img"></div>
      <div className="container-fluid d-flex p-0">
        <BrowserRouter>
          <ToastContainer />

          <TopNav setOpen={setOpen} open={open} handleShow={handleShow} />
          <Navbar open={open} />
          <MobileNavbar handleClose={handleClose} show={show} />
          {/* Routes */}

          <Routes>
            {/* <Route path="/" element={<Dashboardpage open={open} />} /> */}
            <Route
              path="/"
              element={<Landing open={open} />}
            />
            <Route
              path="/createlaunch"
              element={<CreateLaunchPadPage open={open} />}
            />
            <Route path="/launchpad" element={<LaunchPadPage open={open} />} />
            <Route path="/privatesale" element={<PrivateSale open={open} />} />
            <Route path="/price" element={<Price open={open} />} />
            <Route path="/profile" element={<ProfilePage open={open} />} />
            <Route path="/editprofile" element={<ProfileEdit open={open} />} />
            <Route path="/view/:userAddress" element={<ContriView open={open} />} />
            <Route path="/privateview/:userAddress" element={<PrivateView open={open} />} />
            <Route path="/edit/:launchpadAddress" element={<Edit open={open} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
