import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/logo/closeIcon.png";

import Profile_view from "../../assets/profile_view.jpg";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import launchpadFactoryAbi from '../../contract/abis/launchpadFactoryAbi.json'
import Countdown from 'react-countdown';
import { getWeb3, loadWeb3, remoteWeb3 } from '../../connectivity/connectivity';
import { createLaunchpadInfoView } from '../../interfaces/launchpad';
import icoTokenAbi from '../../contract/abis/icoTokenAbi.json'
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { connectWallet } from "../../store/feature/helperFunctionLaunchpadData";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { launchpadFactoryAddress } from "../../contract/Contract";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import { BsDiscord } from 'react-icons/bs';
import { AiFillRedditCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { date } from "yup";
import Counter from "./Counter";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import EmergenyWithdraw from "../Modals/EmergenyWithdraw";
import ClaimTime from "../Modals/ClaimTime";
import Contributors from "../Modals/Contributors";
import AddUserToWhitelist from "../Modals/AddUserToWhitelist";
import { toPlainString } from "../../utilies/utils";
const ZOOM: number = 100
const ContriView = ({ open }: { open: Boolean }) => {
  const [listOfContributors, setListOfContributors] = useState<{ address: string, contribution: number }[]>([]);
  const [buttonTitle, setButtonTitle] = useState('');
  const [updateGetInfo, setUpdateGetInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [userClaimedTokens, setUserClaimedTokens] = useState(0);
  const [userTotalTokens, setUserTotalTokens] = useState(0);
  const [userSaleType, setUserSaleType] = useState('');
  const [showClaimTime, setShowClaimTime] = useState(false);
  const [showUserAddWhitelist, setShowUserAddWhitelist] = useState(false);
  const [userClaimAble, setUserClaimAble] = useState(0);
  const [showContributors, setShowContributors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLaunchpadOwner, setIsLaunchpadOwner] = useState(false);
  const [isemergenyWithdrawContribute, setEmergenyWithdrawContribute] = useState(false);
  const [penalityFee, setPenalityFee] = useState(0)
  const handleClose = () => setShow(false);
  const handleCloseContributors = () => setShowContributors(false);
  const handleShow = () => setShow(true);
  const handleShowContributors = () => setShowContributors(true);
  const navigate = useNavigate();
  const { userAddress } = useParams();
  const dispatch = useAppDispatch();
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice);
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState(0);
  const [launchpadInfo, setLaunchpadInfo] = useState<createLaunchpadInfoView>({
    totalTokens: 0,
    claimedTokens: 0,
    balance: 0,
    description: "",
    discordURL: "",
    endTime: "",
    facebookURL: "",
    feeToken: "",
    githubURL: "",
    hardCap: 0,
    instagramURL: '',
    listingTime: '',
    logoURL: '',
    raisedAmount: '',
    redditURL: '',
    softCap: '',
    startTime: 0,
    state: 0,
    telegramURL: '',
    twitterURL: '',
    websiteURL: '',
    whitelistPool: '',
    totalSupply: 0,
    tokenDecimals: 0,


    icoToken: '',
    tokenName: '',
    tokenSymbol: '',
    currencySymbol: '',
    presaleRate: 0,
    progress: 0,
    listingPercent: 0,
    lockUpTime: 0,
    manualListing: '',
    minInvest: "",
    maxInvest: "",
    timeInfo: {
      timerTitle: "",
      seconds: null,
      backgroundcolor: "",
      color: "",
      title: "",
    },
    status: "",
    youPurchased: 0,
    totalContributors: 0,
    poolType: ""




  })

  const getInfo = async () => {
    try {
      const remote: any = remoteWeb3()
      let launchpadInstance = new remote.eth.Contract(
        launchpadContractAbi,
        userAddress
      );
      let totalInvestment=0
       let claimedTokens=0
        let totalTokens=0
       let refund =0
      let getUserClaimAble


      console.log('totalInvestment', totalInvestment)
      if (isConnected) {
        let acc = await loadWeb3()

        let result = await launchpadInstance.methods.joinInfos(acc).call()
        totalInvestment = result.totalInvestment
        claimedTokens = result.claimedTokens
        totalTokens = result.totalTokens
        getUserClaimAble = await launchpadInstance.methods.getUserClaimAble(acc).call()


      }
      let userClaimedToken = claimedTokens
      setUserClaimedTokens(userClaimedToken)
      let userTotalTokens = totalTokens
      setUserTotalTokens(userTotalTokens)
      let totalContributors = await launchpadInstance.methods.getJoinedUsers().call()


      let listingTime = await launchpadInstance.methods.listingTime().call()
      setUserClaimAble(getUserClaimAble)
      let contributor: {
        address: string,
        contribution: number
      }
      let arr: any = []
      let listOfContributors = totalContributors.map(async (address: string) => {
        let { totalInvestment, claimedTokens, totalTokens, refund } = await launchpadInstance.methods.joinInfos(address).call()
        contributor = {
          address: address,
          contribution: remote.utils.fromWei(String(totalInvestment))
        }

        arr.push(contributor)
      })
      setListOfContributors(arr)

      let poolType = await launchpadInstance.methods.poolType().call();
      poolType = poolType == 0 ? 'Burn' : 'Refund'


      let launchpadInfo = await launchpadInstance.methods.getLaunchpadInfo().call();


      let icoTokenAddress = await launchpadInstance.methods.icoToken().call()
      let icoInstance = new remote.eth.Contract(
        icoTokenAbi,
        icoTokenAddress
      )
      let totalSupply = await icoInstance.methods.totalSupply().call()
      totalSupply = remote.utils.fromWei(totalSupply)
      let tokenDecimals = await icoInstance.methods.decimals().call()
      let tokenSymbol = await icoInstance.methods.symbol().call()
      let tokenName = await icoInstance.methods.name().call()
      let currencySymbol
      if (launchpadInfo.feeToken == '0x0000000000000000000000000000000000000000') {
        currencySymbol = 'BNB'
      }
      else {
        let feeTokenInstance = new remote.eth.Contract(
          icoTokenAbi,
          launchpadInfo.feeToken
        )
        currencySymbol = await feeTokenInstance.methods.symbol().call()
      }


      let obj: createLaunchpadInfoView = {
        claimedTokens: 0,
        totalTokens: 0,
        balance: 0,
        description: "",
        discordURL: "",
        endTime: "",
        facebookURL: "",
        feeToken: "",
        githubURL: "",
        hardCap: 0,
        instagramURL: '',
        listingTime: '',
        logoURL: '',
        raisedAmount: '',
        redditURL: '',
        softCap: '',
        startTime: 0,
        state: 0,
        telegramURL: '',
        twitterURL: '',
        websiteURL: '',
        whitelistPool: '',
        icoToken: '',
        tokenName: '',
        totalSupply: 0,
        tokenDecimals: 0,
        tokenSymbol: '',
        currencySymbol: '',
        presaleRate: 0,
        progress: 0,
        listingPercent: 0,
        lockUpTime: 0,
        manualListing: '',
        timeInfo: {
          timerTitle: "",
          seconds: null,
          backgroundcolor: "",
          color: "",
          title: "",
        },
        minInvest: "",
        maxInvest: "",
        status: "",
        youPurchased: 0,
        totalContributors: 0,
        poolType: ""
      }


      let currentDateSeconds = Math.round(new Date().getTime() / 1000);

      let timeInfo;
      if (launchpadInfo.state == 3) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#e9eaeb",
          color: "#bdc2c4",
          title: "Cancelled",
        };
      }
      else if (currentDateSeconds < Number(launchpadInfo.startTime)) {
        timeInfo = {
          timerTitle: "Sale Starts In",
          seconds: (Number(launchpadInfo.startTime)) * 1000,
          backgroundcolor: "#fdfaea",
          color: "#d29813",
          title: "Upcoming",
        };
      }
      else if ((launchpadInfo.state == 2) || (currentDateSeconds > Number(launchpadInfo.endTime))) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#ffeaef",
          color: "#ff3465",
          title: "sale Ended",
        };
      }
      else if ((launchpadInfo.raisedAmount == launchpadInfo.hardCap) && (currentDateSeconds < Number(launchpadInfo.endTime))) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#d1fae5",
          color: "#10b981",
          title: "Filled",
        };
      }

      else {
        timeInfo = {
          timerTitle: "Sale Ends In",
          seconds: (Number(launchpadInfo.endTime)) * 1000,
          backgroundcolor: "#d1fae5",
          color: "#10b981",
          title: "Sale Live",
        };
      }



      obj.totalTokens = totalTokens
      obj.claimedTokens = claimedTokens
      obj.poolType = poolType
      obj.totalContributors = totalContributors.length
      obj.youPurchased = remote.utils.fromWei(String(totalInvestment))
      obj.status = timeInfo.title == 'Upcoming' ? 'In Coming' : timeInfo.title == 'Cancelled' ? 'cancelled' : timeInfo.title == 'sale Ended' || timeInfo.title == 'Finalized' ? 'ended' : timeInfo.title == 'Sale Live' ? 'in progress' : timeInfo.title == 'Filled' ? 'filled' : 'ended'
      obj.minInvest = remote.utils.fromWei(launchpadInfo.minInvest)
      obj.maxInvest = remote.utils.fromWei(launchpadInfo.maxInvest)
      obj.totalSupply = totalSupply
      obj.tokenDecimals = tokenDecimals
      obj.balance = remote.utils.fromWei(String(launchpadInfo.balance))
      obj.description = launchpadInfo.description
      obj.manualListing = launchpadInfo.manualListing ? 'Manual Listing' : 'Auto Listing'
      obj.discordURL = launchpadInfo.discordURL
      obj.endTime = (launchpadInfo.endTime)
      obj.facebookURL = launchpadInfo.facebookURL
      obj.feeToken = launchpadInfo.feeToken
      obj.githubURL = launchpadInfo.githubURL
      obj.hardCap = remote.utils.fromWei(String(launchpadInfo.hardCap))
      obj.instagramURL = launchpadInfo.instagramURL
      obj.listingTime = launchpadInfo.listingTime
      obj.logoURL = launchpadInfo.logoURL
      obj.raisedAmount = remote.utils.fromWei(String(launchpadInfo.raisedAmount))
      obj.redditURL = launchpadInfo.redditURL
      obj.softCap = remote.utils.fromWei(String(launchpadInfo.softCap))
      obj.startTime = (launchpadInfo.startTime)
      obj.state = launchpadInfo.state
      obj.telegramURL = launchpadInfo.telegramURL
      obj.twitterURL = launchpadInfo.twitterURL
      obj.websiteURL = launchpadInfo.websiteURL
      obj.icoToken = icoTokenAddress
      obj.tokenSymbol = tokenSymbol
      obj.lockUpTime = Number(launchpadInfo.lpLockTime) / 60
      obj.tokenName = tokenName
      obj.progress = (Number(launchpadInfo.raisedAmount) / (launchpadInfo.hardCap)) * 100
      obj.presaleRate = remote.utils.fromWei(String(launchpadInfo.presaleRate))
      obj.currencySymbol = currencySymbol
      obj.listingPercent = Number(launchpadInfo.listingPercent) / ZOOM
      obj.whitelistPool = launchpadInfo.whitelistPool == '0' ? 'Public' : 'Whitelist'
      obj.timeInfo = timeInfo
      console.log('obj', obj)
      setLaunchpadInfo(obj)
      setIsLoading(false)
      setUserSaleType(obj.whitelistPool)
    } catch (error: any) {
      toast.error(error.message)

    }
  }

  const getPenaltyFee = async () => {
    const remote: any = remoteWeb3()
    let launchpadFactoryInstance = new remote.eth.Contract(
      launchpadFactoryAbi,
      launchpadFactoryAddress
    );
    let penaltyFee = await launchpadFactoryInstance.methods.penaltyFeePercent().call()
    setPenalityFee(penaltyFee / ZOOM)
  }
  useEffect(() => {
    getInfo()
    getPenaltyFee()
  }, [btnTxt, updateGetInfo, showClaimTime, isConnected]);
  const getAccount = async () => {
    dispatch(connectWallet())
  }
  const handleAmount = async (value: any) => {
    let actualValue = value
    if (Number(value) > Number(launchpadInfo.maxInvest)) {
      actualValue = launchpadInfo.maxInvest
      if (actualValue > balance) {
        actualValue = balance;
      }
    } else {
      if (Number(value) > (balance)) {
        actualValue = balance
        if (actualValue > Number(launchpadInfo.maxInvest)) {
          actualValue = Number(launchpadInfo.maxInvest);
        }
      }
    }
    let diff = Number(launchpadInfo.hardCap) - Number(launchpadInfo.raisedAmount);


    setAmount(String(actualValue > diff ? diff : actualValue));
    getInfo()
  }
  const getBalance = async () => {


    let remote: any = remoteWeb3()

    let balance: number
    let acc = btnTxt


    if (launchpadInfo.feeToken == '0x0000000000000000000000000000000000000000') {
      balance = await remote.eth.getBalance(acc)
      balance = remote.utils.fromWei(String(balance))
      setBalance(balance)
    }
    else {
      let icoInstance = new remote.eth.Contract(
        icoTokenAbi,
        launchpadInfo.feeToken
      )
      balance = await icoInstance.methods.balanceOf(acc).call()
      balance = remote.utils.fromWei(String(balance))
      setBalance(balance)


    }
  }
  const launchpadOwner = async () => {
    let remote: any = remoteWeb3()
    let launchpadInstance = await new remote.eth.Contract(
      launchpadContractAbi,
      userAddress
    )
    let launchpadOwnerAddress = await launchpadInstance.methods.launchpadOwner().call()
    if (btnTxt == launchpadOwnerAddress) {
      setIsLaunchpadOwner(true)
    }
    else {
      setIsLaunchpadOwner(false)
    }
  }
  useEffect(() => {
    if (isConnected) {
      launchpadOwner()
      getBalance()
    }
  }, [isConnected, launchpadInfo.feeToken, btnTxt]);

  const contribute = async () => {
    try {

      let web3 = await getWeb3()

      let launchpadInstance = await new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      )
      if (userSaleType == 'Whitelist') {

        let whiteListBuyersCount = await launchpadInstance.methods.getWhiteListBuyersCount().call();
        let whiteListBuyers = await launchpadInstance.methods.getAllWhiteListBuyers(0, whiteListBuyersCount).call();

        if (!whiteListBuyers.includes(btnTxt)) {
          throw new Error("You are not whitelist user");
        }
      }
      let sendAmount: string = web3.utils.toWei(toPlainString(amount))
      let etherValue: string = '0';

      if (launchpadInfo.feeToken === "0x0000000000000000000000000000000000000000") {
        etherValue = sendAmount;
      } else {
        let feeTokenInstance = await new web3.eth.Contract(icoTokenAbi, launchpadInfo.feeToken);
        let approvetx = await feeTokenInstance.methods.approve(userAddress, sendAmount).send({ from: btnTxt });
      }

      let contributor = await launchpadInstance.methods.contribute(sendAmount).send({
        from: btnTxt,
        value: sendAmount
      })
      toast.success('success')

      getInfo()
    } catch (error: any) {
      let errorString = error.toString();
      let match = errorString.match(/"message": "(.*?)"/);
      let message
      if (match) {
        message = match[1];


      } else {
        message = error.toString().replace("Error: ", "");
      }
      toast.error(message)
    }


  }
  const emergenyWithdrawContribute = async () => {
    try {
      setEmergenyWithdrawContribute(true)
      let web3 = await getWeb3()

      let launchpadInstance = new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      );
      let txn = await launchpadInstance.methods.emergencyWithdrawContribute().send(
        {
          from: btnTxt
        }
      )
      toast.success('successfully withdraw')

      getInfo()
      setEmergenyWithdrawContribute(false)
    } catch (error: any) {
      toast.error(error.message)
      setEmergenyWithdrawContribute(false)
    }
  }

  const finalizeLaunchpad = async () => {
    if (launchpadInfo.manualListing == 'Manual Listing' && launchpadInfo.listingTime == '0') {
      toast.error('please set claim time first')
    }
    else {
      try {
        const web3: any = await getWeb3()
        let acc = await loadWeb3()
        let launchpadInstance = new web3.eth.Contract(
          launchpadContractAbi,
          userAddress
        );
        let finalizetxn = await launchpadInstance.methods.finalizeLaunchpad().send({
          from: btnTxt
        })
        toast.success('Finalized')
        getInfo()

      } catch (error: any) {
        toast.error(error.message)
      }
    }


  }
  const withdrawContribute = async () => {
    try {
      const web3: any = await getWeb3()
      let acc = await loadWeb3()
      let launchpadInstance = new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      );
      let withdrawContributetxn = await launchpadInstance.methods.withdrawContribute().send({
        from: btnTxt
      })
      toast.success('Withdraw successfully')

      getInfo()

    } catch (error: any) {
      toast.error(error.message)
    }
  }
  const claimToken = async () => {
    try {
      const web3: any = await getWeb3()
      let acc = await loadWeb3()
      let launchpadInstance = new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      );
      let claimTokentxn = await launchpadInstance.methods.claimTokens().send({
        from: btnTxt
      })
      getInfo()

    } catch (error: any) {
      toast.error(error.message)
    }
  }
  const cancelLaunchpad = async () => {
    try {
      const web3: any = await getWeb3()
      let launchpadInstance = new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      );

      let canceltx = await launchpadInstance.methods.cancelLaunchpad().send({ from: btnTxt });
      toast.success('Launchpad cancelled')

      getInfo()

    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const handleMode = async (e: any) => {
    try {
      let value = e.target.value


      let web3 = await getWeb3()

      let launchpadInstance = await new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      )
      let mode: number = value == 'Public' ? 0 : 1


      let whitelistpooltxn = await launchpadInstance.methods.setWhitelistPool(mode).send({
        from: btnTxt
      })
      getInfo()
      setUserSaleType(value)
    } catch (error: any) {
      toast.error(error.message)
    }

  }

  const withdrawCancelTokens = async () => {
    try {

      const web3: any = await getWeb3()
      let launchpadInstance = new web3.eth.Contract(
        launchpadContractAbi,
        userAddress
      );
      let finalizetxn = await launchpadInstance.methods.claimCanceledTokens().send({
        from: btnTxt
      })
      toast.success('successfully withdraw tokens')

      getInfo()
    } catch (error) {

    }
  }
  return (
    <div
      className={
        "mt-5 ms-0 ms-lg-5 mb-5" +
        (open ? " margin-side-open" : " margin-side-close")
      }
    >
      <div className="mt-5">
        <button className="btn btn-outline-primary text-white" onClick={() => navigate(-1)}>Back</button>

      </div>
      <div className="container d-flex mobile_center mt-3">

        <div className="row d-flex w-100">

          <div className="col-lg-7">
            <div className="box-2 p-lg-3 pt-3 mt-2 mb-4">
              <div className="row">
                <div className="col-lg-6 col-6 d-flex">
                  {
                    isLoading ? <>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton circle={true} height={80} width={80} />
                      </SkeletonTheme>
                    </> : <>
                      <img
                        className=" rounded-pill"
                        width={80}
                        height={80}
                        src={launchpadInfo.logoURL}
                        alt=""
                      />
                    </>
                  }

                  <div className="d-block ms-2">
                    <h5>{launchpadInfo.tokenName ||
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">

                        <Skeleton />

                      </SkeletonTheme>
                    }
                      <span className="ms-2"  >
                        {(isLaunchpadOwner && launchpadInfo.state == 1) && <Link to={`/edit/${userAddress} `}
                          className=" text-decoration-none" ><ModeEditOutlineOutlinedIcon ></ModeEditOutlineOutlinedIcon></Link>}

                      </span>
                    </h5>
                    <div className="icons">
                      {/* <TwitterIcon className="text-white" /> */}
                      {
                        launchpadInfo.websiteURL && <Link to={launchpadInfo.websiteURL} target="_blank">
                          <LanguageIcon className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.twitterURL && <Link to={launchpadInfo.twitterURL} target="_blank">
                          <TwitterIcon className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.discordURL && <Link to={launchpadInfo.discordURL} target="_blank">
                          <BsDiscord className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.redditURL && <Link to={launchpadInfo.redditURL} target="_blank">
                          <AiFillRedditCircle className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.facebookURL && <Link to={launchpadInfo.facebookURL} target="_blank">
                          <FacebookIcon className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.githubURL && <Link to={launchpadInfo.githubURL} target="_blank">
                          <GitHubIcon className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.telegramURL && <Link to={launchpadInfo.telegramURL} target="_blank">
                          <TelegramIcon className="text-white " />
                        </Link>
                      }
                      {
                        launchpadInfo.instagramURL && <Link to={launchpadInfo.instagramURL} target="_blank">
                          <InstagramIcon className="text-white " />
                        </Link>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 text-end mt-lg-2">
                  {
                    isLoading ? <>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="rounded-pill w-25 py-2" />
                      </SkeletonTheme>
                    </> : <>
                      <span className="rounded-pill border px-2 py-1" style={{ backgroundColor: `${launchpadInfo.timeInfo.backgroundcolor}`, color: `${launchpadInfo.timeInfo.color}`, fontSize: "12px" }}>
                        <svg stroke="currentColor" style={{ marginRight: '2px' }} fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="7px" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8"></circle></svg>
                        <b>{launchpadInfo.timeInfo.title

                        }</b></span>
                    </>
                  }
                  {/* <span className="dot1 me-2"></span>Sale Live */}


                </div>
              </div>
              <div className="text-white mt-2">{launchpadInfo.description}</div>
              <hr />
              <div className="px-3">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">{launchpadInfo.manualListing}</p>
                      <p className="text-white my-2">
                        Liquidity will {`${launchpadInfo.manualListing == 'Manual Listing' ? 'not' : ''}`} be automatically added!
                      </p>
                    </div>
                  </>}

                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Presale Address</p>
                      <p className="text-white token_code my-2" style={{ overflowWrap: "anywhere" }}>
                        {userAddress}{" "}
                        <CopyAllIcon
                          onClick={() => {
                            navigator.clipboard.writeText(String(userAddress));
                          }}
                        />
                      </p>
                    </div>
                  </>
                }
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Token Name</p>
                      <p className="text-white my-2">{launchpadInfo.tokenName}</p>
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Token Symbol</p>
                      <p className="text-white my-2">{launchpadInfo.tokenSymbol}</p>
                    </div>
                  </>}

                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Total Decimals</p>
                      <p className="text-white my-2">{launchpadInfo.tokenDecimals}</p>
                    </div>
                  </>}

                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Token Address</p>
                      <p className="text-white token_code my-2" style={{ overflowWrap: "anywhere" }}>
                        {launchpadInfo.icoToken}{" "}
                        <CopyAllIcon
                          onClick={() => {
                            navigator.clipboard.writeText(String(launchpadInfo.icoToken));
                          }}
                        />
                      </p>
                    </div>
                  </>}

                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Total Supply</p>
                      <p className="text-white my-2">{launchpadInfo.totalSupply}</p>
                    </div>
                  </>}


                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Presale Rate</p>
                      <p className="text-white my-2">
                        1 {launchpadInfo.currencySymbol} = {launchpadInfo.presaleRate} {launchpadInfo.tokenSymbol}
                      </p>
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Softcap </p>
                      <p className="text-white my-2">{toPlainString(launchpadInfo.softCap)} {launchpadInfo.currencySymbol}</p>
                    </div>
                  </>}

                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">HardCap </p>
                      <p className="text-white my-2">{toPlainString(launchpadInfo.hardCap)} {launchpadInfo.currencySymbol}</p>
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Unsold Tokens</p>
                      <p className="text-white my-2">{launchpadInfo.poolType}</p>
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Claim Time</p>
                      {
                        launchpadInfo.listingTime > '0' ? <>

                          <p className="text-white my-2">{moment(Number(launchpadInfo.listingTime) * 1000).format('LLLL')}</p>

                        </> : <span className="mt-2 text-white">0</span>
                      }
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Presale Start Time</p>

                      <p className="text-white my-2">{moment(Number(launchpadInfo.startTime) * 1000).format('LLLL')}</p>
                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Presale End Time</p>
                      <p className="text-white my-2">{moment(Number(launchpadInfo.endTime) * 1000).format('LLLL')}</p>

                    </div>
                  </>}
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton className="py-2 my-2" />
                    </SkeletonTheme>
                  </> : <>
                    <div className="d-flex justify-content-between bor_bt">
                      <p className="my-2">Liquidity Percent</p>
                      <p className="text-white my-2">{launchpadInfo?.listingPercent}%({launchpadInfo.manualListing})</p>
                    </div></>}

              </div>
            </div>
          </div>

          <div className="col-lg-5 col-12 d-none d-lg-block mt-2">
            <div className="box">
              {
                isLoading ? <>

                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 mt-2 w-25 " />
                  </SkeletonTheme>
                </> : <>
                  <div className="d-flex justify-content-center">
                    <p className="text-white mb-2"> {launchpadInfo.timeInfo.timerTitle != 'null' && launchpadInfo.timeInfo.timerTitle}</p>
                  </div>
                </>}

              {
                launchpadInfo.timeInfo.seconds != null &&
                <Counter updateGetInfo={updateGetInfo} setUpdateGetInfo={setUpdateGetInfo} initialTime={Number(launchpadInfo.timeInfo.seconds)} />
              }


              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 mt-2 rounded-pill" />
                  </SkeletonTheme>
                </> : <>
                  {
                    <div className="d-flex justify-content-center item-progress">
                      <div className="progress mt-3 w-100">
                        <div className={`progress-bar ${launchpadInfo.progress == 100 ? 'bg-success' : 'bg-primary'}`} role="progressbar" style={{ width: `${launchpadInfo.progress}%` }}>
                          {Math.floor(launchpadInfo.progress)}%
                        </div>
                      </div>
                    </div>
                  }

                </>}
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="rounded " />
                  </SkeletonTheme>
                </> : <>
                  {
                    <div className="d-flex justify-content-between  mt-2">
                      <p className="text-white mb-2">{launchpadInfo.raisedAmount} {launchpadInfo.currencySymbol}</p>
                      <p className="text-white mb-2">{launchpadInfo.hardCap} {launchpadInfo.currencySymbol}</p>
                    </div>
                  }

                </>}

              <form>
                <div className="mb-3">
                  {
                    isLoading ? <>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="py-1 mt-2 w-50" />
                      </SkeletonTheme>
                    </> : <>
                      {launchpadInfo.timeInfo.seconds != null && <label htmlFor="exampleInputEmail1" className="form-label">
                        Amount (max:{launchpadInfo.maxInvest} {launchpadInfo.currencySymbol})
                      </label>
                      }
                    </>}
                  {
                    isLoading ? <>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="py-3 mt-2 rounded-5" />
                      </SkeletonTheme>
                    </> :
                      <>

                        {launchpadInfo.timeInfo.seconds == null && <p className="text-white text-center mt-2">This Pool Has {launchpadInfo.timeInfo.title}</p>}
                        {
                          launchpadInfo.timeInfo.seconds != null && <>
                            <div className="d-flex amount">
                              <input
                                type="text"
                                disabled={!isConnected}
                                className="form-control"
                                aria-describedby="emailHelp"
                                placeholder="0.0"
                                value={amount}
                                onChange={(e) => { handleAmount(e.target.value) }}
                              />
                              <button type='button' className="btn max_btn" disabled={!isConnected} onClick={() => { handleAmount(launchpadInfo.maxInvest) }}>MAX</button>

                            </div>
                            {
                              amount.length > 0 &&
                              <p className="text-white">you will receive {toPlainString(Number(amount) * launchpadInfo.presaleRate)} {launchpadInfo.tokenSymbol} </p>

                            }
                          </>
                        }

                      </>
                  }
                  {
                    isLoading ? <>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="py-3 mt-2 rounded-3 w-25" />
                      </SkeletonTheme>
                    </> : <>
                      {isConnected ? <>
                        {
                          (launchpadInfo.timeInfo.title == 'Upcoming' || launchpadInfo.timeInfo.title == 'Sale Live') && <button type='button' className="mt-3 btn btn-primary" disabled={amount == '' || amount == '0' || launchpadInfo.timeInfo.title == 'Upcoming'} onClick={() => { contribute() }} >
                            Buy with {launchpadInfo.currencySymbol}
                          </button>
                        }

                      </> :
                        <>
                          <a className="mt-3 btn btn-primary" onClick={() => getAccount()}>
                            Connect Wallet
                          </a></>}
                    </>}


                </div>
                <hr ></hr>
                {
                  isConnected &&
                    launchpadInfo.youPurchased > 0 ? <>
                    {
                      Date.now() < (Number(launchpadInfo.endTime) * 1000) && launchpadInfo.state == 1 ?
                        <><button type="button" className="btn btn-primary" onClick={() => { handleShow() }}>Emergeny Withdraw</button></>
                        : launchpadInfo.state == 3 || (Date.now() > (Number(launchpadInfo.endTime) * 1000) && (launchpadInfo.raisedAmount < launchpadInfo.softCap))
                          ? <><button type="button" className="btn btn-primary" onClick={() => { withdrawContribute() }} >Withdraw contribute</button></>
                          : <>{
                            userClaimedTokens < userTotalTokens ? <>

                              <button type="button" className="btn btn-primary" disabled={!(launchpadInfo.state == 2 && Number(launchpadInfo.listingTime) > 0)} onClick={() => { claimToken() }}>Claim
                              </button>
                              <span className="ms-3 text-white fw-bold">{
                                launchpadInfo.youPurchased * launchpadInfo.presaleRate > 0 && <>{toPlainString(launchpadInfo.youPurchased * launchpadInfo.presaleRate)} {launchpadInfo.tokenSymbol}</>
                              }</span>
                            </> : <>

                              <button type="button" className="btn btn-primary" disabled >Claimed
                              </button>
                              <span className="ms-3 text-white fw-bold">{
                                launchpadInfo.youPurchased * launchpadInfo.presaleRate > 0 && <>{toPlainString(launchpadInfo.youPurchased * launchpadInfo.presaleRate)} {launchpadInfo.tokenSymbol}</>
                              }</span>
                            </>
                          }</>

                    }
                  </> : ''
                }

              </form>
              <EmergenyWithdraw penalityFee={penalityFee} showEmergenyWithdraw={show} handleClose={handleClose} emergenyWithdrawContribute={emergenyWithdrawContribute} />


              {/* Owner END */}

            </div>
            <div className="box mt-3">
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 my-2" />
                  </SkeletonTheme>
                </> : <>
                  <div className="d-flex justify-content-between bor_bt ">
                    <p className="my-2">Status</p>
                    <p className="text-white my-2">{launchpadInfo.status}</p>
                  </div></>}
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 my-2" />
                  </SkeletonTheme>
                </> : <>

                  <div className="d-flex justify-content-between bor_bt">
                    <p className=" my-2">Sale Type</p>
                    <p className="text-white my-2">{launchpadInfo.whitelistPool}</p>
                  </div></>}
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 my-2" />
                  </SkeletonTheme>
                </> : <>
                  <div className="d-flex justify-content-between bor_bt">
                    <p className="my-2">Minimum Buy</p>
                    <p className="text-white my-2"> {toPlainString(launchpadInfo.minInvest)} {launchpadInfo.currencySymbol}</p>
                  </div></>}
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 my-2" />
                  </SkeletonTheme>
                </> : <>
                  <div className="d-flex justify-content-between bor_bt">
                    <p className="my-2">Maximum Buy</p>
                    <p className="text-white my-2">{toPlainString(launchpadInfo.maxInvest)} {launchpadInfo.currencySymbol}</p>
                  </div>
                </>}

              {isConnected &&
                <>
                  <div className="d-flex justify-content-between bor_bt ">
                    <p className="my-2">Your Contribution</p>
                    <p className="text-white my-2">{launchpadInfo.youPurchased} {launchpadInfo.currencySymbol}</p>
                  </div>
                  <div className="d-flex justify-content-between bor_bt ">
                    <p className="my-2">You purchased</p>
                    <p className="text-white my-2">{toPlainString(launchpadInfo.youPurchased * launchpadInfo.presaleRate)} {launchpadInfo.tokenSymbol}</p>
                  </div>

                </>
              }
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton className="py-2 my-2" />
                  </SkeletonTheme>
                </> : <>

                  <div className="d-flex justify-content-between bor_bt ">
                    <p className="my-2">Total Contributors</p>
                    <p className="text-white my-2">{launchpadInfo.totalContributors}</p>
                  </div></>}

            </div>
            {isLaunchpadOwner &&
              <div className="box mt-3">
                <h5 className="mt-3">Owner Zone</h5>
                <div className="box2 border-warning d-flex justify-content-between">
                  <p className="text-center mt-2 text-white">
                    To make sure there will be no issues during the presale time, please do not send tokens to wallets before you finalize the presale pool.
                  </p>
                </div>
                <div className="box2 border-warning d-flex justify-content-between">
                  <p className=" text-center mt-2">
                    Pool Fee: 5% BNB raised only
                  </p>
                </div>
                <h5 className="mt-3">Sale Type</h5>
                <div className="d-flex ">
                  <div className="form-check">
                    <input className="form-check-input" checked={userSaleType == 'Public'} type="radio" name="Public" value="Public" onChange={(e: any) => handleMode(e)} />
                    <label className="form-check-label" >
                      Public
                    </label>
                  </div>
                  <div className="form-check ms-3">
                    <input className="form-check-input" checked={userSaleType == 'Whitelist'} type="radio" name="whitelist" value="Whitelist" onChange={(e: any) => handleMode(e)} />
                    <label className="form-check-label">
                      Whitelist
                    </label>
                  </div>

                </div>
                {
                  userSaleType == 'Whitelist' && <div className="col-lg-12 d-grid mt-2">

                    <button className="over_btn w-100" onClick={() => { setShowUserAddWhitelist(true); setButtonTitle('insert') }}>Add users to whitelist</button>
                    <button className="over_btn w-100" onClick={() => { setShowUserAddWhitelist(true); setButtonTitle('remove') }}>Remove users from whitelist</button>
                    <button className="over_btn w-100" value='Public' onClick={(e: any) => handleMode(e)} >Disable whitelist</button>

                  </div>
                }

                <h5 className="mt-3">Pool Actions</h5>
                <div className="col-lg-12 d-grid">
                  {

                    listOfContributors.length > 0 ? <>
                      <button className="over_btn w-100" onClick={handleShowContributors} >List Of Contributors</button>

                    </> : ''
                  }
                  <button className="over_btn w-100" disabled={launchpadInfo.timeInfo.title == 'Upcoming' || (launchpadInfo.state == 2) || launchpadInfo.timeInfo.title == 'Sale Live' || (launchpadInfo.timeInfo.title == 'sale Ended' && launchpadInfo.raisedAmount < launchpadInfo.softCap)} onClick={() => finalizeLaunchpad()}>Finalize</button>
                  <button className="over_btn w-100" onClick={() => { setShowClaimTime(true) }}>set Claim Time</button>
                  {
                    launchpadInfo.state == 3 &&

                    <button className="over_btn w-100" onClick={() => { withdrawCancelTokens() }}>Withdraw cancelled tokens</button>

                  }
                  {
                    !(launchpadInfo.state == 2 || launchpadInfo.timeInfo.title == 'Cancelled') && <button className="over_btn w-100" onClick={cancelLaunchpad}>Cancel Launchpad</button>
                  }
                </div>
                <AddUserToWhitelist getInfo={getInfo} acc={btnTxt} userAddress={userAddress} launchpadContractAbi={launchpadContractAbi} buttonTitle={buttonTitle} showUserAddWhitelist={showUserAddWhitelist} setShowUserAddWhitelist={setShowUserAddWhitelist} />
                <ClaimTime getInfo={getInfo} showClaimTime={showClaimTime} setShowClaimTime={setShowClaimTime} userAddress={userAddress} />
                <Contributors listOfContributors={listOfContributors} showContributors={showContributors} handleCloseContributors={handleCloseContributors} />
                <hr />
                <video width="100%" height="100%" controls autoPlay muted>
                  <source
                    src="https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            }

          </div>


        </div>
      </div>
    </div>
  );
};

export default ContriView;
