import bg_profile from "../../assets/bg_profile.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
const MyToken = () => {
  return (
    <div>
      <div className="row w-100 justify-content-center">
        <h5 className="mt-3">My Token</h5>
        <div className="box_over col-lg-12 mt-1 ms-4">
          <div className="d-block d-lg-flex">
            <div className="col-lg-5">
              <div className="d-flex">
                <img className="profile_pic" src={bg_profile} alt="" />
                <div className="d-block ms-3">
                  <h5 className="mb-0">usman</h5>
                  <p className="mb-0">usi</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <p className="mb-0">Contract address</p>
              <p className="mb-0">0x455fB6684Db46Ee</p>
            </div>
          </div>
          <div className="col-lg-12 mt-3 d-block d-lg-flex justify-content-between">
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Create private sale</button>
            </div>
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Create launchpad</button>
            </div>
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Create airdrop</button>
            </div>
          </div>
        </div>
        <div className="box_over col-lg-12 mt-3 ms-4">
          <div className="d-block d-lg-flex">
            <div className="col-lg-5">
              <div className="d-flex">
                <img className="profile_pic" src={bg_profile} alt="" />
                <div className="d-block ms-3">
                  <h5 className="mb-0">usman</h5>
                  <p className="mb-0">usi</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <p className="mb-0">Contract address</p>
              <p className="mb-0">0x455fB6684Db46026AEe</p>
            </div>
          </div>
          <div className="col-lg-12 mt-3 d-block d-lg-flex justify-content-between">
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Create private sale</button>
            </div>
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Create launchpad</button>
            </div>
            <div className="col-lg-4">
              {" "}
              <button className="over_btn">Coming soonCreate airdrop</button>
            </div>
          </div>
        </div>

        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Trending (24h)</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-center align-items-center text-center">
              <p>No Data</p>
            </div>
          </div>
        </div>
        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Recent LaunchPad</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-center align-items-center text-center">
              <p>No Data</p>
            </div>
          </div>
        </div>
        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Recent Private Sale</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-between">
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">
                  1. swap progress dash check 1
                </h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">2. Test 1</h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">3. TESTING</h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">4. SWPA TEST1</h6>
                <VisibilityIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyToken;
