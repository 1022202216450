import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getLaunchapads, getMyContributions } from './helperFunctionLaunchpadData'
import { string } from 'yup'

// Define a type for the slice state
// interface launchpadData {

// }
// Define the initial state using that type
const initialState = {
  allLaunchpad: <any>[],
  myContribution: <any>[],
  upComing: [''],
  live: [''],
  filled: [''],
  ended: [''],
  cancelled: [''],
  isLaunchpadDataLoading: false,
  activeTab: 'allLaunchpad',
  filterName: 'my',
  activeFilter:'all',
  launcpadType:'',
}
export const launchpadData = createSlice({
  name: 'launchpadData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateLaunchpadType: (state, action: PayloadAction<string>) => {
      state.launcpadType = action.payload
    },
    updateActiveFilter: (state, action: PayloadAction<string>) => {
      console.log('action.payload',action.payload)
      state.activeFilter = action.payload
    },
    filterNameTab: (state, action: PayloadAction<string>) => {
      state.filterName = action.payload
    },
    updateTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    updateUpComing: (state, action: PayloadAction<string>) => {
      state.upComing.push(action.payload)
    },
    empty: (state) => {
      state.ended = []
      state.filled = []
      state.live = []
      state.upComing = []
      state.cancelled = []
      state.myContribution=[]
      state.allLaunchpad=[]
    },
    updateLive: (state, action: PayloadAction<string>) => {
      state.live.push(action.payload)

    },
    updateFilled: (state, action: PayloadAction<string>) => {
      state.filled.push(action.payload)

    },
    updateCancelled: (state, action: PayloadAction<string>) => {
      state.cancelled.push(action.payload)

    },
    updateEnded: (state, action: PayloadAction<string>) => {
      state.ended.push(action.payload)

    },
    upDateFilter: (state, action: PayloadAction<any[]>) => {
      console.log('upDateFilter', action.payload)
      state.allLaunchpad = action.payload
    },
    upDateFilterForMyContribution: (state, action: PayloadAction<any[]>) => {
      console.log('upDateFilterForMyContribution', action.payload)
      state.myContribution = action.payload
    }
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: {
    [getLaunchapads.pending.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getLaunchapads.fulfilled.type]: (state: any, { payload }: any) => {
      state.allLaunchpad = payload
      state.isLaunchpadDataLoading = false
    },
    [getLaunchapads.rejected.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getMyContributions.pending.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getMyContributions.fulfilled.type]: (state: any, { payload }: any) => {
      state.myContribution = payload
      state.isLaunchpadDataLoading = false
    },
    [getMyContributions.rejected.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
  }
})
export const {updateActiveFilter,updateLaunchpadType, updateUpComing, updateEnded, updateLive, updateCancelled, updateFilled, upDateFilter, updateTab, upDateFilterForMyContribution, empty, filterNameTab } = launchpadData.actions
export default launchpadData.reducer