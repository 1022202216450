import React from 'react'
import LaunchPadSteps from '../../Components/CreateLaunchPad/LaunchPadSteps'

const CreateLaunchPadPage = ({ open }:{open:Boolean}) => {
  return (
    <div className='w-100 flex-wrap'>
      <LaunchPadSteps open={open} />
    </div>
  )
}

export default CreateLaunchPadPage
