import { useState } from "react";
import bg_profile from "../../assets/bg_profile.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
const EditProfile = ({ open }: { open: Boolean }) => {
  const [image, setImage] = useState(bg_profile);
  const [profileImg, setProfileImg] = useState(bg_profile);
  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const onProfileImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImg(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <div
      className={
        "mt-5 ms-0 ms-lg-5" +
        (open ? " margin-side-open" : " margin-side-close")
      }
    >
      <div className="container-fluid p-0">
        <div className="row w-100 justify-content-center align-items-center mob_mar">
          <div className="col-lg-12 col-12">
            <div className="file-input">
              <input
                type="file"
                name="file-input"
                id="file-input"
                className="file-input__input"
                onChange={onImageChange}
              />
              <label className="file-input__label" htmlFor="file-input">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  className="svg-inline--fa fa-upload fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  ></path>
                </svg>
                <span>Edit Cover Image</span>
              </label>

              <img src={image} alt="" height={300} width={"100%"} />
            </div>
          </div>
          <div className="col-lg-11 col-11  bg-dark margin_rev rounded_bor p-3">
            <div className="d-flex">
              <div className="profile_pic">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={onProfileImageChange}
                    />
                    <label htmlFor="imageUpload">
                      <CameraAltIcon className="cam_icon" />
                    </label>
                  </div>
                  <div className="">
                    <img
                      className="rounded_pic"
                      src={profileImg}
                      alt=""
                      height={60}
                      width={60}
                    />
                  </div>
                </div>
              </div>
              <div className="profile_name ms-3">
                <h5>Usman</h5>
                <p>USI</p>
              </div>
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Display name
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Username
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Username"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Email address
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Mail"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Wallet Connect
              </button>
              <div className="d-flex mt-2 justify-content-end gap-2">
                <button
                  type="submit"
                  className="btn bg-transparent border-0 outline-0 text-white"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
            <div className="box_over mt-3 ms-4">
              <div className="col-lg-12">
                <div className="d-flex">
                  <h5>Recent LaunchPad</h5>
                  <button className="ms-auto view_btn">View</button>
                </div>
                <div className="box_over rounded justify-content-center align-items-center text-center">
                  <p>No Data</p>
                </div>
              </div>
            </div>
            <div className="box_over mt-3 ms-4">
              <div className="col-lg-12">
                <div className="d-flex">
                  <h5>Recent Private Sale</h5>
                  <button className="ms-auto view_btn">View</button>
                </div>
                <div className="box_over rounded justify-content-between">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-white fs-5">
                      1. swap progress dash check 1
                    </h6>
                    <VisibilityIcon />
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-white fs-5">2. Test 1</h6>
                    <VisibilityIcon />
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-white fs-5">3. TESTING</h6>
                    <VisibilityIcon />
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-white fs-5">4. SWPA TEST1</h6>
                    <VisibilityIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
