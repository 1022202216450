import React from 'react'
import Profile from '../../Components/Profile/Profile'

const ProfilePage = ({ open }:{open:Boolean}) => {
  return (
    <div>
      <Profile open={open}/>
    </div>
  )
}

export default ProfilePage
