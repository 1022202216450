import { Link } from 'react-router-dom';
import cardimg from "../../assets/cardimg.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import LanguageIcon from "@mui/icons-material/Language";
import ethereum from "../../assets/ethereum.png";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import '../CreateLaunchPad/CreateLaunchPad.css'
import LaunchPadCards from "../LaunchPad/LaunchPadCards";
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
// import { getAllLaunchpadData } from '../../store/feature/helperFunctionLaunchpadData';
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import Countdown from 'react-countdown';
import Counter from './Counter';
import { getWeb3, remoteWeb3 } from '../../connectivity/connectivity';
import { createLaunchpadInfo } from '../../interfaces/launchpad';
import TokenSymbols from './renders/TokenSymbols';
import { tokenAbi } from '../../utilies/Contract';
import icoTokenAbi from '../../contract/abis/icoTokenAbi.json'
import { updateLive, updateFilled, updateCancelled, updateEnded, updateUpComing, updateActiveFilter, } from '../../store/feature/launchpadDataSlice';
const ZOOM: number = 100
const DashboardCard = ({ address }: { address: string }) => {

  const [isLoading, setIsLoading] = useState(true)

  const [launchpadInfo, setLaunchpadInfo] = useState<createLaunchpadInfo>({
    balance: 0,
    description: "",
    discordURL: "",
    endTime: "",
    facebookURL: "",
    feeToken: "",
    githubURL: "",
    hardCap: 0,
    instagramURL: '',
    listingTime: '',
    logoURL: '',
    raisedAmount: '',
    redditURL: '',
    softCap: '',
    startTime: 0,
    state: 0,
    telegramURL: '',
    twitterURL: '',
    websiteURL: '',
    whitelistPool: '',
    icoToken: '',
    tokenName: '',
    tokenSymbol: '',
    currencySymbol: '',
    presaleRate: 0,
    progress: 0,
    tokenDecimals: 0,
    totalSupply: 0,
    listingPercent: 0,
    lockUpTime: 0,
    manualListing: '',
    timeInfo: {
      timerTitle: "",
      seconds: null,
      backgroundcolor: "",
      color: "",
      title: "",
    },

  })
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  const filter = useSelector(
    (state: RootState) => state.launchpadData.activeFilter
  );
  const dispatch = useAppDispatch();
  const getInfo = async () => {
    try {
      const remote: any = remoteWeb3()
      let launchpadInstance = new remote.eth.Contract(
        launchpadContractAbi,
        address
      );
      let launchpadInfo = await launchpadInstance.methods.getLaunchpadInfo().call();
      let icoTokenAddress = await launchpadInstance.methods.icoToken().call()
      let icoInstance = new remote.eth.Contract(
        icoTokenAbi,
        icoTokenAddress
      )
      let totalSupply = await icoInstance.methods.totalSupply().call()
      let tokenSymbol = await icoInstance.methods.symbol().call()
      let tokenDecimals = await icoInstance.methods.decimals().call()
      let tokenName = await icoInstance.methods.name().call()
      let currencySymbol
      if (launchpadInfo.feeToken == '0x0000000000000000000000000000000000000000') {
        currencySymbol = 'BNB'
      }
      else {
        let feeTokenInstance = new remote.eth.Contract(
          icoTokenAbi,
          launchpadInfo.feeToken
        )
        currencySymbol = await feeTokenInstance.methods.symbol().call()
      }

      let obj: createLaunchpadInfo = {
        balance: 0,
        description: "",
        discordURL: "",
        endTime: "",
        facebookURL: "",
        feeToken: "",
        githubURL: "",
        hardCap: 0,
        instagramURL: '',
        listingTime: '',
        logoURL: '',
        raisedAmount: '',
        redditURL: '',
        softCap: '',
        startTime: 0,
        state: 0,
        telegramURL: '',
        twitterURL: '',
        websiteURL: '',
        whitelistPool: '',
        icoToken: '',
        tokenName: '',
        tokenSymbol: '',
        currencySymbol: '',
        presaleRate: 0,
        progress: 0,
        listingPercent: 0,
        lockUpTime: 0,
        manualListing: '',
        tokenDecimals: 0,
        totalSupply: 0,
        timeInfo: {
          timerTitle: "",
          seconds: null,
          backgroundcolor: "",
          color: "",
          title: "",
        },
      }

      let currentDateSeconds = Math.round(new Date().getTime() / 1000);

      let timeInfo;
      if (launchpadInfo.state == 3) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#e9eaeb",
          color: "#bdc2c4",
          title: "Cancelled",
        };
        // dispatch(updateCancelled(address))
      }
      else if (currentDateSeconds < Number(launchpadInfo.startTime)) {
        timeInfo = {
          timerTitle: "Sale Starts In",
          seconds: (Number(launchpadInfo.startTime)) * 1000,
          backgroundcolor: "#fdfaea",
          color: "#d29813",
          title: "Upcoming",
        };
        // dispatch(updateUpComing(address))

      }
      else if ((launchpadInfo.state == 2) || (currentDateSeconds > Number(launchpadInfo.endTime))) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#ffeaef",
          color: "#ff3465",
          title: "sale Ended",
        };
        // dispatch(updateEnded(address))

      }
      else if ((launchpadInfo.raisedAmount == launchpadInfo.hardCap) && (currentDateSeconds < Number(launchpadInfo.endTime))) {
        timeInfo = {
          timerTitle: "null",
          seconds: null,
          backgroundcolor: "#d1fae5",
          color: "#10b981",
          title: "Filled",
        };
        // dispatch(updateFilled(address))

      }

      else {
        timeInfo = {
          timerTitle: "Sale Ends In",
          seconds: (Number(launchpadInfo.endTime)) * 1000,
          backgroundcolor: "#d1fae5",
          color: "#10b981",
          title: "Sale Live",
        };
        dispatch(updateLive(address))

      }
      obj.totalSupply = totalSupply
      obj.tokenDecimals = tokenDecimals
      obj.balance = remote.utils.fromWei(String(launchpadInfo.balance))
      obj.description = launchpadInfo.description
      obj.manualListing = launchpadInfo.manualListing ? 'Manual Listing' : 'Auto Listing'
      obj.discordURL = launchpadInfo.discordURL
      obj.endTime = launchpadInfo.endTime
      obj.facebookURL = launchpadInfo.facebookURL
      obj.feeToken = launchpadInfo.feeToken
      obj.githubURL = launchpadInfo.githubURL
      obj.hardCap = remote.utils.fromWei(String(launchpadInfo.hardCap))
      obj.instagramURL = launchpadInfo.instagramURL
      obj.listingTime = launchpadInfo.listingTime
      obj.logoURL = launchpadInfo.logoURL
      obj.raisedAmount = remote.utils.fromWei(String(launchpadInfo.raisedAmount))
      obj.redditURL = launchpadInfo.redditURL
      obj.softCap = remote.utils.fromWei(String(launchpadInfo.softCap))
      obj.startTime = (launchpadInfo.startTime * 1000)
      obj.state = launchpadInfo.state
      obj.telegramURL = launchpadInfo.telegramURL
      obj.twitterURL = launchpadInfo.twitterURL
      obj.websiteURL = launchpadInfo.websiteURL
      obj.icoToken = icoTokenAddress
      obj.tokenSymbol = tokenSymbol
      obj.lockUpTime = Number(launchpadInfo.lpLockTime) / 60
      obj.tokenName = tokenName
      obj.progress = (Number(launchpadInfo.raisedAmount) / (launchpadInfo.hardCap)) * 100
      obj.presaleRate = remote.utils.fromWei(String(launchpadInfo.presaleRate))
      obj.currencySymbol = currencySymbol
      obj.listingPercent = Number(launchpadInfo.listingPercent) / ZOOM
      obj.whitelistPool = launchpadInfo.whitelistPool
      obj.timeInfo = timeInfo
      console.log('obj', obj)
      setLaunchpadInfo(obj)
      setIsLoading(false)
    } catch (error: any) {
      console.error('error while get info', error)
    }
  }

  useEffect(() => {
    getInfo()
  }, [btnTxt]);

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      getInfo()
    } else {
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };


  return (

    <>
      {
        filter == 'all' && <div className="col-lg-4 col-md-11 col-11 px-lg-0 px-2 mx-lg-0 mx-2 gap-2 d-lg-flex d-block mb-3">

          <div className="col-lg-12 box_card justify-content-between">
            <div className="d-flex">
              <div className="col-3">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton circle={true} height={80} width={80} />
                    </SkeletonTheme>
                  </>
                    : <img src={launchpadInfo.logoURL} alt="img" className="card_img rounded-circle" height={80} width={80} />

                }
              </div>
              <div className="ms-4 col-4 mt-3">
                {/* <h5 className=""><TokenSymbols address={address} /></h5> */}
                <h5 className="">{launchpadInfo.tokenName
                  || <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton className='w-75' />

                  </SkeletonTheme>
                }</h5>
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton />

                    </SkeletonTheme>
                  </> : <>
                    <h6>1 {launchpadInfo.currencySymbol} = {launchpadInfo.presaleRate} {launchpadInfo.tokenSymbol}</h6>

                  </>
                }
              </div>
              <div className="col-lg-5 col-4 text-end">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className="rounded-pill  px-2 py-1" />

                    </SkeletonTheme>
                  </> : <>
                    <span className="rounded-pill border me-2 p-1 me-lg-2 p-lg-2 new_font" style={{ backgroundColor: `${launchpadInfo.timeInfo.backgroundcolor}`, color: `${launchpadInfo.timeInfo.color}`, fontSize: "12px" }}>
                      <svg stroke="currentColor" style={{ marginRight: '2px' }} fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="7px" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8"></circle></svg>
                      <b>{launchpadInfo.timeInfo.title}</b></span>
                  </>
                }

              </div>
            </div>

            <div className="col-lg-12  mt-4">
              <p className="mb-0">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className='w-25' />

                    </SkeletonTheme>
                  </> :
                    <>
                      Soft/Hard</>
                }
              </p>
              <p className="mt-0">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className='w-50' />

                    </SkeletonTheme>
                  </> : <>
                    {launchpadInfo.softCap} {launchpadInfo.currencySymbol} - {launchpadInfo.hardCap} {launchpadInfo.currencySymbol}

                  </>

                }
              </p>
            </div>
            <div className="item-progress">

              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <span className='mt-4'>
                      <Skeleton className="progress rounded-pill" />
                    </span>
                  </SkeletonTheme>
                </> : <>
                  <div className="progress mt-4 mt-md-3">
                    <div className={`progress-bar ${launchpadInfo.progress == 100 ? 'bg-success' : 'bg-primary'}`} role="progressbar" style={{ width: `${launchpadInfo.progress}%` }}>
                      {Math.floor(launchpadInfo.progress)}%
                    </div>
                  </div>
                </>
              }
            </div>


            <div className="progress-sale d-flex justify-content-between">
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton />

                  </SkeletonTheme>
                </> : <>
                  <span className="text-white">{launchpadInfo.raisedAmount} {launchpadInfo.currencySymbol}</span>

                </>
              }
              {
                isLoading ? <>

                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton />

                  </SkeletonTheme>
                </> : <>
                  <span className="text-white">{launchpadInfo.hardCap} {launchpadInfo.currencySymbol}</span>

                </>
              }
            </div>
            {
              isLoading ? <>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">

                  <Skeleton />

                </SkeletonTheme>
              </> : <>
                <div className="progress-sale d-flex justify-content-between mt-3">

                  <span className="text-white">Liquidity %:</span>
                  <span className="text-white">{launchpadInfo?.listingPercent}%({launchpadInfo.manualListing})</span>
                </div>

              </>
            }
            {
              isLoading ? <>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">

                  <Skeleton />

                </SkeletonTheme>
              </> : <>
                <div className="progress-sale d-flex justify-content-between">
                  <span className="text-white">Lockup Time:</span>
                  <span className="text-white">{launchpadInfo.lockUpTime == 0 ? 'UnLocked' : `${launchpadInfo.lockUpTime} minutes`} </span>
                </div>
              </>
            }

            <hr className="text-white"></hr>
            <div className="d-flex mt-3 justify-content-between">
              <div className="">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton />

                    </SkeletonTheme>
                  </> : <>
                    {launchpadInfo.timeInfo.seconds != null && <><strong> {launchpadInfo.timeInfo.timerTitle} :</strong><br /></>}
                    {launchpadInfo.timeInfo.seconds == null && <><strong> Sale :{launchpadInfo.timeInfo.title}</strong><br /></>}

                  </>
                }


                {
                  launchpadInfo.timeInfo.seconds != null &&
                  <Countdown

                    date={
                      Date.now() + (Number(launchpadInfo.timeInfo.seconds) - Date.now())

                    }
                    key={launchpadInfo.timeInfo.seconds}

                    renderer={renderer}
                  />
                  // <Counter initialTime={Number(launchpadInfo.timeInfo.seconds)} />
                }
              </div>

              <div className="icons">
                {
                  isLoading ? <>
                    {/* <SkeletonTheme baseColor="#202020" highlightColor="#444">

                  <Skeleton className='w-25' />

                </SkeletonTheme> */}
                  </> : <>

                    <Link to={launchpadInfo.websiteURL} target="_blank">
                      <LanguageIcon className="text-white " />

                    </Link>
                  </>
                }


                {isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton className='btn_progress border-0 rounded-pill' />

                  </SkeletonTheme>
                </> :
                  <Link
                    to={`${`/view/${address}`}`}
                    className="btn_progress text-decoration-none px-3 ms-2"
                  >
                    <span className='text-white'>View</span>
                  </Link>

                }

              </div>
            </div>
          </div>
        </div>
      }
      {
        filter == launchpadInfo.timeInfo.title && <div className="col-lg-4 col-12 gap-2 d-lg-flex d-block mb-3">

          <div className="col-lg-12 box_card justify-content-between">
            <div className="d-flex">
              <div className="col-3">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton circle={true} height={80} width={80} />
                    </SkeletonTheme>
                  </>
                    : <img src={launchpadInfo.logoURL} alt="img" className="card_img rounded-circle" height={80} width={80} />

                }
              </div>
              <div className="ms-4 col-5">
                {/* <h5 className=""><TokenSymbols address={address} /></h5> */}
                <h5 className="">{launchpadInfo.tokenName
                  || <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton className='w-75' />

                  </SkeletonTheme>
                }</h5>
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton />

                    </SkeletonTheme>
                  </> : <>
                    <h6>1 {launchpadInfo.currencySymbol} = {launchpadInfo.presaleRate} {launchpadInfo.tokenSymbol}</h6>

                  </>
                }
              </div>
              <div className="col-lg-4 col-4 text-end pe-0 pe-lg-3">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className="rounded-pill  px-2 py-1" />

                    </SkeletonTheme>
                  </> : <>
                    <span className="rounded-pill border px-2 py-1" style={{ backgroundColor: `${launchpadInfo.timeInfo.backgroundcolor}`, color: `${launchpadInfo.timeInfo.color}`, fontSize: "12px" }}>
                      <svg stroke="currentColor" style={{ marginRight: '2px' }} fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="7px" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8"></circle></svg>
                      <b>{launchpadInfo.timeInfo.title}</b></span>
                  </>
                }

              </div>
            </div>

            <div className="col-lg-12  mt-4">
              <p className="mb-0">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className='w-25' />

                    </SkeletonTheme>
                  </> :
                    <>
                      Soft/Hard</>
                }
              </p>
              <p className="mt-0">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton className='w-50' />

                    </SkeletonTheme>
                  </> : <>
                    {launchpadInfo.softCap} {launchpadInfo.currencySymbol} - {launchpadInfo.hardCap} {launchpadInfo.currencySymbol}

                  </>

                }
              </p>
            </div>
            <div className="item-progress">

              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <span className='mt-4'>
                      <Skeleton className="progress rounded-pill" />
                    </span>
                  </SkeletonTheme>
                </> : <>
                  <div className="progress mt-4 mt-md-3">
                    <div className={`progress-bar ${launchpadInfo.progress == 100 ? 'bg-success' : 'bg-primary'}`} role="progressbar" style={{ width: `${launchpadInfo.progress}%` }}>
                      {Math.floor(launchpadInfo.progress)}%
                    </div>
                  </div>
                </>
              }
            </div>


            <div className="progress-sale d-flex justify-content-between">
              {
                isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton />

                  </SkeletonTheme>
                </> : <>
                  <span className="text-white">{launchpadInfo.raisedAmount} {launchpadInfo.currencySymbol}</span>

                </>
              }
              {
                isLoading ? <>

                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton />

                  </SkeletonTheme>
                </> : <>
                  <span className="text-white">{launchpadInfo.hardCap} {launchpadInfo.currencySymbol}</span>

                </>
              }
            </div>
            {
              isLoading ? <>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">

                  <Skeleton />

                </SkeletonTheme>
              </> : <>
                <div className="progress-sale d-flex justify-content-between mt-3">

                  <span className="text-white">Liquidity %:</span>
                  <span className="text-white">{launchpadInfo?.listingPercent}%({launchpadInfo.manualListing})</span>
                </div>

              </>
            }
            {
              isLoading ? <>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">

                  <Skeleton />

                </SkeletonTheme>
              </> : <>
                <div className="progress-sale d-flex justify-content-between">
                  <span className="text-white">Lockup Time:</span>
                  <span className="text-white">{launchpadInfo.lockUpTime == 0 ? 'UnLocked' : `${launchpadInfo.lockUpTime} minutes`} </span>
                </div>
              </>
            }

            <hr className="text-white"></hr>
            <div className="d-flex mt-3 justify-content-between">
              <div className="">
                {
                  isLoading ? <>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">

                      <Skeleton />

                    </SkeletonTheme>
                  </> : <>
                    {launchpadInfo.timeInfo.seconds != null && <><strong> {launchpadInfo.timeInfo.timerTitle} :</strong><br /></>}
                    {launchpadInfo.timeInfo.seconds == null && <><strong> Sale :{launchpadInfo.timeInfo.title}</strong><br /></>}

                  </>
                }


                {
                  launchpadInfo.timeInfo.seconds != null &&
                  <Countdown

                    date={
                      Date.now() + (Number(launchpadInfo.timeInfo.seconds) - Date.now())

                    }
                    key={launchpadInfo.timeInfo.seconds}

                    renderer={renderer}
                  />
                  // <Counter initialTime={Number(launchpadInfo.timeInfo.seconds)} />
                }
              </div>

              <div className="icons mt-3">
                {
                  isLoading ? <>
                    {/* <SkeletonTheme baseColor="#202020" highlightColor="#444">

              <Skeleton className='w-25' />

            </SkeletonTheme> */}
                  </> : <>

                    <Link to={launchpadInfo.websiteURL} target="_blank">
                      <LanguageIcon className="text-white " />

                    </Link>
                  </>
                }


                {isLoading ? <>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">

                    <Skeleton className='btn_progress border-0 rounded-pill' />

                  </SkeletonTheme>
                </> :
                  <Link
                    to={`${`/view/${address}`}`}
                    className="btn_progress text-decoration-none px-3 ms-2"
                  >
                    <span className='text-white'>View</span>
                  </Link>

                }

              </div>
            </div>
          </div>
        </div>
      }
    </>

  )
}

export default DashboardCard
