import React from 'react'
import DashboardCard from '../../Components/DashboardCards.tsx/DashboardCard'
import DashboardTabs from '../../Components/DashboardCards.tsx/DashboardTabs'
const Dashboardpage = ({ open }:{open:Boolean}) => {
  return (
    <div className='w-100 flex-wrap'>
      <DashboardTabs open={open}/>
      {/* <DashboardCard open={open}/> */}
    </div>
  )
}

export default Dashboardpage

