// import { toast } from 'react-toastify';


// export const isMobile = () => /Mobi/i.test(window.navigator.userAgent)
//     || /iPhone|iPod|iPad/i.test(navigator.userAgent);

// export const objectMap = (object, mapFn) => {
//     return Object.keys(object).reduce((result, key) => {
//         result[key] = mapFn(object[key]);
//         return result
//     }, {})
// }
// export const NETWORKS = {
//     1: {
//         name: "Ethereum",
//         rpcURL: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//         currency: {
//             name: "Ether",
//             symbol: "ETH",
//             decimals: 18
//         },
//         testnetID: 4,
//         blockExplorerURL: "https://etherscan.io"
//     },
//     42: {
//         name: "Kovan",
//         rpcURL: "https://kovan.infura.io/v3/84842078b09946638c03157f83405213",
//         currency: {
//             name: "Ether",
//             symbol: "ETH",
//             decimals: 18
//         },
//         testnetID: 42,
//         blockExplorerURL: "https://kovan.etherscan.io"
//     },
//     4: {
//         name: "Rinkeby",
//         rpcURL: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//         currency: {
//             name: "Ether",
//             symbol: "ETH",
//             decimals: 18
//         },
//         testnetID: 4,
//         blockExplorerURL: "https://rinkeby.etherscan.io"
//     },
//     137: {
//         name: "Polygon",
//         rpcURL: "https://polygon-rpc.com/",
//         currency: {
//             name: "Matic",
//             symbol: "MATIC",
//             decimals: 18
//         },
//         testnetID: 80001,
//         blockExplorerURL: "https://polygonscan.com"
//     },
//     80001: {
//         name: "Mumbai (Polygon Testnet)",
//         rpcURL: "https://rpc-mumbai.maticvigil.com/",
//         currency: {
//             name: "Matic",
//             symbol: "MATIC",
//             decimals: 18
//         },
//         testnetID: 80001,
//         blockExplorerURL: "https://mumbai.polygonscan.com"
//     },
//     56: {
//         name: "Binance",
//         rpcURL: "https://bsc-dataseed1.binance.org",
//         currency: {
//             name: "Binance Coin",
//             symbol: "BNB",
//             decimals: 18,
//         },
//         testnetID: 97,
//         blockExplorerURL: "https://bscscan.com",
//     },
//     97: {
//         name: "Binance Smart Chain Testnet",
//         rpcURL: "https://data-seed-prebsc-1-s1.binance.org:8545",
//         currency: {
//             name: "Binance Coin",
//             symbol: "tBNB",
//             decimals: 18,
//         },
//         testnetID: 97,
//         blockExplorerURL: "https://testnet.bscscan.com",
//     },
//     1285: {
//         name: "Moonriver",
//         rpcURL: "https://rpc.moonriver.moonbeam.network",
//         currency: {
//             name: "MOVR",
//             symbol: "MOVR",
//             decimals: 18,
//         },
//         testnetID: 1287,
//         blockExplorerURL: "https://blockscout.moonriver.moonbeam.network"
//     },
//     1287: {
//         name: "Moonbase Alpha",
//         rpcURL: "https://rpc.testnet.moonbeam.network",
//         currency: {
//             name: "DEV",
//             symbol: "DEV",
//             decimals: 18,
//         },
//         testnetID: 1287,
//         blockExplorerURL: "https://moonbase-blockscout.testnet.moonbeam.network"
//     }
// }
export const walletShortFormer = (wallet) => {
    return `${wallet?.slice(0, 7)}...${wallet?.slice(wallet?.length - 5, wallet?.length)}`
}

// export const copyToClip = (id, message) => {

//     var copyText = document.getElementById(`${id}`);

//     /* Select the text field */
//     copyText.select();
//     copyText.setSelectionRange(0, 99999); /* For mobile devices */

//     /* Copy the text inside the text field */
//     navigator.clipboard.writeText(copyText.value);
//     alert(message)
// }
// export const nativeCurrency = 'BNB'