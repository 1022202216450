import { Button, TextareaAutosize, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { getWeb3, loadWeb3, remoteWeb3 } from "../../connectivity/connectivity";
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import { useSelector } from "react-redux";
import { SocialLinks } from "../../interfaces/launchpad";

const EditLaunchpad = ({ open }: { open: Boolean }) => {
  const navigate = useNavigate();
  const { launchpadAddress } = useParams();
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  const createLaunchpadSchema = Yup.object().shape({
    logoUrl: Yup.string()
      .matches(
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        "Enter correct url!"
      )
      .required("Please enter url"),
    websiteUrl: Yup.string()
      .required("website url is a requird field")
      .matches(
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

        "Enter correct url!"
      ),
    discordUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    facebookUrl: Yup.string().matches(
      /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
      "Enter correct url!"
    ),
    twitterUrl: Yup.string().matches(
      /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
      "Enter correct url!"
    ),
    githubUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    instagramUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    redditUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
    telegramUrl: Yup.string().matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

      "Enter correct url!"
    ),
  });
  const formik = useFormik({
    initialValues: {

      logoUrl: "",
      discription: "",
      websiteUrl: "",
      discordUrl: "",
      twitterUrl: "",
      facebookUrl: "",
      redditUrl: "",
      githubUrl: "",
      telegramUrl: "",
      instagramUrl: "",

    },
    validationSchema: createLaunchpadSchema,

    onSubmit: async (values, action) => {
      try {
        const remote: any = await getWeb3()
        let acc = await loadWeb3()
        let launchpadInstance = new remote.eth.Contract(
          launchpadContractAbi,
          launchpadAddress
        );
        let _logoURL: string = values.logoUrl;
        let _description: string = values.discription;
        let _websiteURL: string = values.websiteUrl;
        let _facebookURL: string = values.facebookUrl;
        let _twitterURL: string = values.twitterUrl;
        let _githubURL: string = values.githubUrl
        let _telegramURL: string = values.telegramUrl
        let _instagramURL: string = values.instagramUrl;
        let _discordURL: string = values.discordUrl;
        let _redditURL: string = values.redditUrl;
        let socialLinks: SocialLinks = [
          _logoURL,
          _description,
          _websiteURL,
          _facebookURL,
          _twitterURL,
          _githubURL,
          _telegramURL,
          _instagramURL,
          _discordURL,
          _redditURL,
        ];
        let editLaunchpadtxn = await launchpadInstance.methods.editLaunchpad(socialLinks).send({
          from: acc
        });
        navigate(-1)

      } catch (error) {
        console.error('error while edit launchpad', error)
      }
    },
  });
  const getLaunchpadInfo = async () => {
    const remote: any = remoteWeb3()
    let acc = await loadWeb3()
    let launchpadInstance = new remote.eth.Contract(
      launchpadContractAbi,
      launchpadAddress
    );
    let launchpadInfo = await launchpadInstance.methods.getLaunchpadInfo().call();
    formik.setValues({
      logoUrl: launchpadInfo.logoURL,
      discription: launchpadInfo.description,
      websiteUrl: launchpadInfo.websiteURL,
      discordUrl: launchpadInfo.discordURL,
      twitterUrl: launchpadInfo.twitterURL,
      facebookUrl: launchpadInfo.facebookURL,
      redditUrl: launchpadInfo.redditURL,
      githubUrl: launchpadInfo.githubURL,
      telegramUrl: launchpadInfo.telegramURL,
      instagramUrl: launchpadInfo.instagramURL,
    })
  }
  console.log('formikvalues', formik.values)
  useEffect(() => {
    getLaunchpadInfo()

  }, []);
  console.log('address', btnTxt)
  return (
    <div
      className={
        "mt-5 ms-0 ms-lg-5 mb-5" +
        (open ? " margin-side-open" : " margin-side-close")
      }
    >
      <div className="text-white mt-5 px-5">
        <div className="container d-flex mobile_center">
          <div className="row d-flex w-100">
            <div className="col-lg-7">
              <form onSubmit={formik.handleSubmit}>
                <h2 className="text-white">Add Logo</h2>
                <p className="mb-0">
                  URL must end with a supported image extension png, jpg,
                  jpeg or gif. Use our “Create Logo URL” to generate a URL
                  link for you.
                </p>
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Logo pic URL{" "}
                    <Tooltip title='You may either provide the URL of your logo image or utilize our "Create logo URl" feature to generate a URL link for your logo" arrow'>
                      <InfoOutlinedIcon className="info_icon" />
                    </Tooltip>
                  </label>
                  <input
                    type="url"
                    className="form-control py-1 mb-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="logo Url"
                    name="logoUrl"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.logoUrl}
                  />
                  <div className="text-start">
                    {formik.errors.logoUrl && (
                      <Form.Text className="text-danger">
                        {formik.errors.logoUrl}
                      </Form.Text>
                    )}
                  </div>
                </div>
                <div className="currency mt-5 ">
                  <h3>Add Description</h3>
                  <p className="mb-0">
                    Let users know more about your project by adding
                    detailed info about your token.
                  </p>
                  <h3 className="mt-3">
                    Enter Token description{" "}
                    <Tooltip title="Add a comprehensive token discription to help your users understand your prjoct better.">
                      <InfoOutlinedIcon className="info_icon" />
                    </Tooltip>
                  </h3>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Enter Discription"
                    value={formik.values.discription}
                    onChange={(e) => formik.handleChange(e)}
                    name="discription"
                    minRows={5}
                    style={{
                      width: 700,
                      backgroundColor: "black",
                      color: "white",
                    }}
                  />
                </div>
                <h3 className="mt-5 mt-lg-3">Social links </h3>
                <p className="mb-0">
                  Provide your socials as each link will display on your
                  launchpad page. Viewers will be able to visit your
                  links.
                </p>
                <hr />
                <h3 className="mt-3">Add Social link </h3>
                <p className="mb-0">
                  You must provide a website and a twitter account of your
                  token so users can know more about your project.
                  Otherwise, it is optional to add more social links
                </p>
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Website</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Website Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="websiteUrl"
                      value={formik.values.websiteUrl}
                    />
                    <div className="text-start">
                      {formik.errors.websiteUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.websiteUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Facebook</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Facebook Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="facebookUrl"
                      value={formik.values.facebookUrl}
                    />
                    <div className="text-start">
                      {formik.errors.facebookUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.facebookUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Twitter</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="User name"
                      onChange={(e) => formik.handleChange(e)}
                      name="twitterUrl"
                      value={formik.values.twitterUrl}
                    />
                    <div className="text-start">
                      {formik.errors.twitterUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.twitterUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Github</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Github Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="githubUrl"
                      value={formik.values.githubUrl}
                    />
                    <div className="text-start">
                      {formik.errors.githubUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.githubUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Telegram</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="telegram Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="telegramUrl"
                      value={formik.values.telegramUrl}
                    />
                    <div className="text-start">
                      {formik.errors.telegramUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.telegramUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Instagram</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="instagram Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="instagramUrl"
                      value={formik.values.instagramUrl}
                    />
                    <div className="text-start">
                      {formik.errors.instagramUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.instagramUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Discord</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="discord Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="discordUrl"
                      value={formik.values.discordUrl}
                    />
                    <div className="text-start">
                      {formik.errors.discordUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.discordUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-2">
                    <p className="px-3 py-1 p_bor">Reddit</p>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="url"
                      className="form-control py-1"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="reddit Url"
                      onChange={(e) => formik.handleChange(e)}
                      name="redditUrl"
                      value={formik.values.redditUrl}
                    />
                    <div className="text-start">
                      {formik.errors.redditUrl && (
                        <Form.Text className="text-danger">
                          {formik.errors.redditUrl}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="  style_next  text-white  rounded" style={{ backgroundColor: 'transparent' }}
                  >
                    Back
                  </button>



                  <button
                    disabled={!(formik.isValid && formik.dirty)}
                    type="submit"
                    className="  style_next ms-2  text-white  rounded" style={{ backgroundColor: 'transparent' }}
                  >
                    Update
                  </button>
                </div>

              </form>
            </div>


          </div>
        </div>

      </div>
    </div>
  )
};

export default EditLaunchpad;
