import { useEffect, useState } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './dashboard.css'
import DashboardCard from './DashboardCard';
import MyContribution from './MyContribution';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { getLaunchapads, getMyContributions } from '../../store/feature/helperFunctionLaunchpadData';
import { remoteWeb3 } from "../../connectivity/connectivity";
import launchpadContractAbi from '../../contract/abis/launchpadContractAbi.json'
import { updateLive, updateFilled, updateCancelled, updateEnded, updateUpComing, updateTab, empty, filterNameTab, updateActiveFilter, } from '../../store/feature/launchpadDataSlice';

const DashboardTabs = ({ open }: { open: Boolean }) => {
  const { isConnected, btnTxt } = useSelector(
    (state: any) => state.createLaunchpadSlice
  );
  const allLaunchpadData = useSelector(
    (state: RootState) => state.launchpadData.allLaunchpad
  );

  const myContribution = useSelector(
    (state: RootState) => state.launchpadData.myContribution
  );
  console.log('myContributionmyContribution', myContribution)
  const [key, setKey] = useState('all');
  const [visibleALlLaunchpad, setVisibleAllLaunchpad] = useState(9);
  const [visibleMyContribution, setVisibleMyContribution] = useState(9);

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getLaunchapads());


  }, []);
  useEffect(() => {

    if (isConnected) {
      dispatch(getMyContributions(btnTxt))

    }

  }, [btnTxt]);
  const showMoreItems = () => {
    setVisibleAllLaunchpad((prevValue) => prevValue + 9);
  };
  const showMoreContributionItems = () => {
    setVisibleMyContribution((prevValue) => prevValue + 9);
  };


  const makeFillterArray = async (address: string) => {
    const remote: any = remoteWeb3()
    let launchpadInstance = new remote.eth.Contract(
      launchpadContractAbi,
      address
    );
    let launchpadInfo = await launchpadInstance.methods.getLaunchpadInfo().call();

    let currentDateSeconds = Math.round(new Date().getTime() / 1000);

    let timeInfo;
    if (launchpadInfo.state == 3) {
      dispatch(updateCancelled(address))
    }
    else if (currentDateSeconds < Number(launchpadInfo.startTime)) {
      dispatch(updateUpComing(address))
    }
    else if ((launchpadInfo.state == 2) || (currentDateSeconds > Number(launchpadInfo.endTime))) {
      dispatch(updateEnded(address))
    }
    else if ((launchpadInfo.raisedAmount == launchpadInfo.hardCap) && (currentDateSeconds < Number(launchpadInfo.endTime))) {
      dispatch(updateFilled(address))
    }
    else {
      dispatch(updateLive(address))
    }
  }
  // useEffect(() => {
  //   dispatch(filterNameTab(key))
  //   if (key == 'all') {
  //     dispatch(updateTab('all'))
  //     for (let i = 0; i < allLaunchpadData.length; i++) {
  //       makeFillterArray(allLaunchpadData[i])
  //     }
  //   }
  //   else {
  //     dispatch(updateTab('my'))
  //     console.log(' myContribution.length', myContribution.length)
  //     for (let i = 0; i < myContribution.length; i++) {
  //       makeFillterArray(myContribution[i])
  //     }
  //   }
  // }, [allLaunchpadData, myContribution]);
  const handleClick = (k: string | null) => {
    // dispatch(empty())
    // dispatch(updateActiveFilter('all'))

    if (k) {
      setKey(k);
      // dispatch(updateTab(k))
    }
  }
  return (
    <div  >
      <Tabs
        activeKey={key}
        onSelect={handleClick}
        id="fill-tab-example"
        className="tabs_top justify-content-center"
        fill
      >
        <Tab eventKey="all" title="All LaunchPads" >
          <div
            className={
              "mt-4 ms-0 ms-lg-5 mb-5" +
              (open ? " margin-side-open" : " margin-side-close")
            }
          >
            <div className="row w-100 gap-4 gx-0 justify-content-lg-center justify-content-center">
              {
                allLaunchpadData.length == 0 ? <>
                  <div className="px-3">
                    <div className="box_private ">
                      <p>No Sale Found</p>
                    </div>
                  </div>
                </> :
                  <>
                    {allLaunchpadData?.slice(0).reverse().slice(0)?.map((item: any) => {
                      return (
                        <DashboardCard address={item} key={item} />
                      );
                    })}
                  </>
              }

            </div>
            {/* {visibleALlLaunchpad < allLaunchpadData.length &&
              <div className="text-center">
                <button className="btn_progress text-decoration-none px-3 " onClick={showMoreItems}>
                  View More
                </button>
              </div>
            } */}
          </div>
        </Tab>
        <Tab eventKey="my" title="My Contributions" >
          <div
            className={
              "mt-4 ms-0 ms-lg-5 mb-5" +
              (open ? " margin-side-open" : " margin-side-close")
            }
          >
            <div className="row w-100 gx-0 justify-content-lg-start justify-content-center">
              {
                myContribution.length == 0 ? <>
                  <div className="box_private px-lg-0 px-3">
                    <p>No Sale Found</p>
                  </div>
                </> : <>
                  {myContribution?.slice(0).reverse().slice(0).map((item: any,) => {
                    return (
                      <DashboardCard address={item} key={item} />
                    );
                  })}
                </>
              }

            </div>
            {/* {isConnected && visibleMyContribution < myContribution.length &&
              <div className="text-center">
                <button className="btn_progress text-decoration-none px-3 " onClick={showMoreContributionItems}>
                  View More
                </button>
              </div>
            } */}
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}

export default DashboardTabs
