import React from 'react'
import LaunchPadFilter from '../../Components/LaunchPad/LaunchPadFilter'
import LaunchPadCards from '../../Components/LaunchPad/LaunchPadCards'
import Dashboardpage from '../DashboardPage/Dashboardpage'

const LaunchPadPage = ({ open }: { open: Boolean }) => {
  return (
    <div className='w-100 flex-wrap p-1 p-lg-0'>
      <LaunchPadFilter open={open} launchpadTitle='Create launchpad'/>
      <Dashboardpage open={open} />
      {/* <LaunchPadCards open={open} buttonTitle={'Participate'} /> */}
    </div>
  )
}

export default LaunchPadPage
