import React from 'react'
import Landingpage from '../../Components/LandingPage/Landingpage'
import PriceCoverter from '../../Components/PriceCoverter'

const Price = ({ open }:{ open: Boolean}) => {
  return (
    <div className='w-100 flex-wrap'>
      <PriceCoverter  open={open}/>
    </div>
  )
}

export default Price
