import HomeIcon from "@mui/icons-material/Home";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import BarChartIcon from '@mui/icons-material/BarChart';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/",
  },
  {
    id: 1,
    icon: <TravelExploreIcon />,
    text: "Presale",
    link: "launchpad",
  },
  {
    id: 2,
    icon: <BarChartIcon />,
    text: "Private Sale",
    link: "privatesale",
  }
];