import React, { useState,ChangeEvent } from 'react'
import '../App.css'
const PriceCoverter = ({ open }: { open: Boolean }) => {
    const [submit,setSubmit] = useState("");
  const success = (()=>{
    if(submit === ""){
        return setSubmit('Please Enter an Amount')
    }else{
       return setSubmit('Amount Recieved')
    }
   
  })
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSubmit(newValue);
  };
  return (
    <div  className={
        "mt-5 ms-5" + " " + (open ? "margin-side-open" : " margin-side-close")
      }>
        <div className='row w-100 p-3'>
        <h1 className='text-white mt-5 pt-5'>Currency Exchange</h1>
      <h1 className='text-white'>Please give your Usdt and take VRC here</h1>
      <div className='col-lg-6 col-12 mt-5' style={{position:'relative'}}>
        <input type='number' className='w-100 p-3 rounded-pill border-0 outline-0' value={submit} onChange={handleInputChange} required placeholder='Enter usdt amount here' style={{position:'relative'}}/>
    
        <button className='btn btn-success bg-launch submit' type="submit" onClick={()=>{success()}}>Submit</button>
        <p className='text-end mt-3 text-danger' >{submit}</p>
      </div>
        </div>
       
      
    </div>
  )
}

export default PriceCoverter
