import Web3 from "web3";
import { rpc } from "../utilies/utils";
let isItConnected = false;
const networks = {
  bsc: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: "Binance smart chain",
    nativeCurrency: {
      name: "BSC",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};
const changeNetwork = async ({ networkName:any }: any) => {
  try {
    if (!(window as any).ethereum) throw new Error("No crypto wallet found");
    await (window as any).ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          // ...networks[networkName],
        },
      ],
    });
  } catch (err) {
    console.log("not found");
  }
};
const handleNetworkSwitch = async (networkName:any) => {
  await changeNetwork({ networkName });
};
let accounts;
const getAccounts = async () => {
  
  const web3 =  (window as any).web3;
  try {
    accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (error) {
    console.log("Error while fetching acounts: ", error);
    return null;
  }
};
export const disconnectWallet = async () => {
  await (window as any).ethereum.request({
    method: "eth_requestAccounts",
    params: [{ eth_accounts: {} }],
  });
  console.log("disconnect");
}; 
export const getWeb3=async()=>{
  if ((window as any).ethereum) {
    return (window as any).web3 
  }
}
export const remoteWeb3=()=>{
return new Web3(rpc)
}
export const loadWeb3 = async () => {
  try {
    if ((window as any).ethereum) {
      (window as any).web3 = new Web3((window as any).ethereum);
      await (window as any).ethereum.enable();
      await (window as any).web3.eth.getChainId((err:any, netId:any) => {
        // console.log("networkId==>", netId);
        switch (netId.toString()) {
          case "97":
            isItConnected = true;
            break;
          default:
            handleNetworkSwitch("bsc");
            isItConnected = false;
        }
      });
      if (isItConnected == true) {
        let accounts = await getAccounts();
        return accounts[0];
      } else {
        let res = "Wrong Network";
        return res;
      }
    } else {
      let res = "No Wallet";
      return res;
    }
  } catch (error) {
    let res = "No Wallet";
    return res;
  }
};