import VisibilityIcon from "@mui/icons-material/Visibility";
const Recent = () => {
  return (
    <div>
      <div className="row w-100 justify-content-center">
        <div className=" mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Recently visited</h5>
            </div>
            <div className="box_over rounded justify-content-center align-items-center text-center">
              <p>No recent view found</p>
            </div>
          </div>
        </div>
        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Trending (24h)</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-center align-items-center text-center">
              <p>No Data</p>
            </div>
          </div>
        </div>
        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Recent LaunchPad</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-center align-items-center text-center">
              <p>No Data</p>
            </div>
          </div>
        </div>
        <div className="box_over mt-3 ms-4">
          <div className="col-lg-12">
            <div className="d-flex">
              <h5>Recent Private Sale</h5>
              <button className="ms-auto view_btn">View</button>
            </div>
            <div className="box_over rounded justify-content-between">
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">
                  1. swap progress dash check 1
                </h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">2. Test 1</h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">3. TESTING</h6>
                <VisibilityIcon />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-white fs-5">4. SWPA TEST1</h6>
                <VisibilityIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recent;
