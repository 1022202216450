import React from "react";
import launchpad from "../../assets/Character_1-01-01.svg";
import launchicon from "../../assets/launchicon.png";
import { NavLink } from "react-router-dom";
import cardimg from "../../assets/cardimg.png";
import ethereum from "../../assets/ethereum.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import launchbase from "../../assets/Pika_1-06.svg";
import launch2 from "../../assets/Pika_1-05.svg";
import launch3 from "../../assets/Pika_1-04.svg";
import CardsSlider from "./CardsSlider";
import woodentable from "../../assets/woodentable.png";
import end from "../../assets/Character_2-01.svg";
import binancechain from "../../assets/binancechain.png";
import celolaunch from "../../assets/celolaunch.png";
import polkadotlaunch from "../../assets/polkadotlaunch.png";
import vrcscan from "../../assets/vrcscan.gif";

const Landingpage = ({ open }: { open: Boolean }) => {
  return (
    <div
      className={
        "mt-5 ms-5" + " " + (open ? "margin-side-open" : " margin-side-close")
      }
    >
      <div className="">
        <div className="row gx-0">
          <div className="col-lg-6 col-sm-12 mt-3 p-lg-0 p-3">
            <h2 className="text-white mt-5">
              {" "}
              A Launchpad for AR, VR and <br />
              Initial Metaverse Offerings <br />
              Own The Future
            </h2>
            <p>
              We facilitate the early stage funding of top AR, VR{" "}
              <br className="d-lg-block d-none" /> projects, Metaverse projects,
              and their <br /> communities by amplifying them <br /> through
              culture.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
            <img
              src={launchpad}
              alt=""
              className="img-fluid w-75 launch_zoom mt-4"
            />
          </div>
        </div>
        <div className="row gx-0 align-items-center">
          <div className="col-lg-6 col-sm-12">
            <img
              src={vrcscan}
              alt=""
              className="img-fluid mt-lg-0 mt-3 vrc_logo mb-lg-0 mb-2  icons_dash1 d-flex align-items-center text-center mx-auto"
            />
          </div>
          <div className="col-lg-5 col-sm-12 mb-lg-0 mb-5">
            <h2 className="text-center text-white mt-2 mb-3">
              Multichain <span className="live_btn ms-2">COMING SOON</span>
            </h2>
            <div className="box_card p-lg-2 p-3 w-lg-50 w-md-100">
              <img
                src={launchicon}
                alt=""
                className="img-fluid icons_dash2 d-flex text-center mx-auto"
              />
              <img
                src={binancechain}
                alt=""
                className="img-fluid icons_dash d-flex text-center mx-auto"
              />
              <img
                src={celolaunch}
                alt=""
                className="img-fluid icons_dash d-flex text-center mx-auto"
                style={{ width: "170px" }}
              />
              <img
                src={polkadotlaunch}
                alt=""
                className="img-fluid icons_dash d-flex text-center mx-auto"
              />
            </div>
          </div>
        </div>

        <div className="row gx-0 justify-content-center  mt-lg-4 mt-0">
          <h1 className="text-white text-center mt-lg-3 mt-0 mb-3">
            Our Offerings
          </h1>
          <div className="col-lg-11 col-sm-12 ">
            <div className="box d-lg-flex d-sm-block w-100 wooden   padding_mbl">
              <div className="col-lg-7 col-sm-12 text-center">
                <h2 className="text-white mt-4 pt-3 mb-0">
                  Initial Metaverse Offering
                </h2>
                <p>
                  Introducing the IMO. Incubated projects with AR, VR{" "}
                  <br className="d-lg-block d-none" /> and Metaverse
                  integrations and solutions. Powered by communities{" "}
                  <br className="d-lg-block d-none" /> from around the world.
                  Own The Future.
                </p>
                <NavLink to="/launchpad">
                  <button className="btn btn-success bg-launch">
                    Launchpad
                  </button>
                </NavLink>
              </div>
              <div className="col-lg-5 col-sm-12 text-lg-center text-center mt-lg-0 mt-3 pos">
                <img src={launchbase} alt="" className="img-fluid base" />
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-0 justify-content-center mt-4">
          <div className="col-lg-11 col-sm-12 mt-4">
            <div className="box d-lg-flex d-sm-block wooden mob_pad">
              <div className=" col-lg-5 col-sm-12  text-center mt-lg-0 mt-3 pos">
                <img
                  src={launch2}
                  alt=""
                  className="img-fluid launch2 ms-lg-5 ms-0 order-2"
                />
              </div>
              <div className="col-lg-7 col-sm-12 text-center order-1">
                <h2 className="text-white  mt-lg-5 pt-lg-3 mt-0 pt-0 mb-0">
                  Amplified through Culture
                </h2>
                <p className="mb-3">
                  A place to buy and <br className="d-lg-none d-block" />
                  Our Celebrity, Influencer, and Industry Partners - providing{" "}
                  <br className="d-lg-none d-block" /> unparalleled reach and
                  social backing to VRCstarter Projects.
                </p>
                <NavLink to="/launchpad">
                  <button className="btn btn-success mb-3 bg-launch">
                    Launchpad
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-0 justify-content-center mt-4 mb-5">
          <div className="col-lg-11 col-sm-12 mt-4">
            <div className="box d-lg-flex d-sm-block box_pad wooden padding_mbl">
              <div className="col-lg-7 col-sm-12 text-center">
                <h2 className="text-white mt-5 pt-3">
                  Community over Everything
                </h2>
                <p className="">
                  We don’t just launch tokens, we launch communities. Giving
                  early adopters the first chance{" "}
                  <br className="d-lg-block d-none" /> at participation,
                  governance, and access.
                </p>
                <NavLink to="/launchpad">
                  <button className="btn btn-success bg-launch">
                    Application
                  </button>
                </NavLink>
              </div>
              <div className="col-lg-5 text-lg-center text-center d-block col-sm-12 mt-lg-0 mt-3 pos">
                <img src={launch3} alt="" className="img-fluid base base_wid" />
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-0">
          <div className="col-lg-12 text-center mb-0">
            <h2 className="text-white text-center mt-0 pt-0 mb-0">FAQ</h2>
            <h5>How Can Projects use VRCpad?</h5>
            <p>
              The VRCpad platform allows early-stage crypto projects to raise
              funds through <br className="d-lg-block d-none" /> our IMO launch
              process. In utilizing the Vrcpad Networks, projects{" "}
              <br className="d-lg-block d-none" /> are able to reach massive
              audiences and fund at <br className="d-lg-block d-none" /> an
              unmatched pace. We tokenize culture.
            </p>
          </div>
        </div>
        <CardsSlider open />
      </div>
      <div className="row gx-0 justify-content-center align-items-center mt-4">
        <div className="col-lg-11 col-sm-12 mt-4">
          <div className="box d-lg-flex d-sm-block wooden story">
            <div className="col-lg-7 col-sm-12 text-center">
              <p className="text-white mt-5 mb-0">Stories</p>
              <h1 className="text-white">Contact Us Here</h1>
              <div className="icons">
                <TwitterIcon className="text-white" />
                <TelegramIcon className="text-white ms-3" />
                <LanguageIcon className="text-white ms-3" />
              </div>
            </div>
            <div className="col-lg-5 text-lg-center text-center d-block col-sm-12 mt-lg-0 mt-3 pos">
              <img src={end} alt="" className="img-fluid base" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
