import React, { Component } from "react";
import Slider from "react-slick";
import cardimg from '../../assets/cardimg.png'
import ethereum from '../../assets/ethereum.png'
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from '@mui/icons-material/Telegram'
import { NavLink } from "react-router-dom";

interface CardsSliderProps {
  open: boolean;
}

export default class CardsSlider extends Component<CardsSliderProps> {
  render() {
    const { open } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true,
    };
    const responsiveSettings = [
      {
        breakpoint: 768, // Screen width at which settings change
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ];
    return (
      <div className="text-center">
        <h2 className="text-white mt-3 mb-3">Upcoming Projects</h2>
        <Slider {...settings} responsive={responsiveSettings}>
          <div className="col-12 p-lg-0 p-3">
            <div className="box_card mx-auto">
            <div className="d-flex">
                    <div className="col-3">
                       <img src={cardimg} alt="img" className="card_img" />
                     </div>
                     <div className="ms-4 col-5">
                       <h5 className="">Animalia</h5>
                       <h6>Registration in:</h6>
                     </div>
                     <div className="col-lg-3 col-4 text-end">
                       <img src={ethereum} alt="" className="ete_ion" />
                     </div>
                   </div>

               <p className="mt-2"> Animalia is an independent free-to-play online Nft trading card game featuring crypto-inspired meme creatures and gemstones. Powered by the BNB Chain </p>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>50K</p>
                </div>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>TBA</p>
                </div>
                <div className="d-block justify-content-between">
                    <h5>
                        TBA
                    </h5>
                    <p>WhiteList Coming Soon</p>
                </div>
                  <div className="item-progress">
                    <div className="progress mt-4 mt-md-5">
                      <div className="progress-bar w-75" role="progressbar">
                        80%
                      </div>
                    </div>
                    <div className="progress-sale d-flex justify-content-between mt-3">
                      <span className="text-white">0/94,752 MECHA</span>
                      <span className="text-white">0 BUSD</span>
                    </div>
                  </div>
                  <div className="d-flex mt-3 justify-content-between">
                    <div className="">
                      <NavLink to="/launchpad"
                        className="btn_progress text-decoration-none"
                      >
                        Launch Pad
                      </NavLink>
                    </div>

                    <div className="icons">
                      <TwitterIcon className="text-white" />
                      <TelegramIcon className="text-white ms-3" />
                      <LanguageIcon className="text-white ms-3" />
                    </div>
                  </div>
            </div>
          </div>
          <div className="col-12 p-lg-0 p-3">
          <div className="box_card mx-auto">
            <div className="d-flex">
                    <div className="col-3">
                       <img src={cardimg} alt="img" className="card_img" />
                     </div>
                     <div className="ms-4 col-5">
                       <h5 className="">Animalia</h5>
                       <h6>Registration in:</h6>
                     </div>
                     <div className="col-lg-3 col-4 text-end">
                       <img src={ethereum} alt="" className="ete_ion" />
                     </div>
                   </div>

               <p className="mt-2"> Animalia is an independent free-to-play online Nft trading card game featuring crypto-inspired meme creatures and gemstones. Powered by the BNB Chain </p>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>50K</p>
                </div>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>TBA</p>
                </div>
                <div className="d-block justify-content-between">
                    <h5>
                        TBA
                    </h5>
                    <p>WhiteList Coming Soon</p>
                </div>
                  <div className="item-progress">
                    <div className="progress mt-4 mt-md-5">
                      <div className="progress-bar w-75" role="progressbar">
                        80%
                      </div>
                    </div>
                    <div className="progress-sale d-flex justify-content-between mt-3">
                      <span className="text-white">0/94,752 MECHA</span>
                      <span className="text-white">0 BUSD</span>
                    </div>
                  </div>
                  <div className="d-flex mt-3 justify-content-between">
                    <div className="">
                      <NavLink to="/launchpad"
                        className="btn_progress text-decoration-none"
                      >
                        Launch Pad
                      </NavLink>
                    </div>

                    <div className="icons">
                      <TwitterIcon className="text-white" />
                      <TelegramIcon className="text-white ms-3" />
                      <LanguageIcon className="text-white ms-3" />
                    </div>
                  </div>
            </div>
          </div>
          <div className="col-12 p-lg-0 p-3">
          <div className="box_card mx-auto">
            <div className="d-flex">
                    <div className="col-3">
                       <img src={cardimg} alt="img" className="card_img" />
                     </div>
                     <div className="ms-4 col-5">
                       <h5 className="">Animalia</h5>
                       <h6>Registration in:</h6>
                     </div>
                     <div className="col-lg-3 col-4 text-end">
                       <img src={ethereum} alt="" className="ete_ion" />
                     </div>
                   </div>

               <p className="mt-2"> Animalia is an independent free-to-play online Nft trading card game featuring crypto-inspired meme creatures and gemstones. Powered by the BNB Chain </p>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>50K</p>
                </div>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>TBA</p>
                </div>
                <div className="d-block justify-content-between">
                    <h5>
                        TBA
                    </h5>
                    <p>WhiteList Coming Soon</p>
                </div>
                  <div className="item-progress">
                    <div className="progress mt-4 mt-md-5">
                      <div className="progress-bar w-75" role="progressbar">
                        80%
                      </div>
                    </div>
                    <div className="progress-sale d-flex justify-content-between mt-3">
                      <span className="text-white">0/94,752 MECHA</span>
                      <span className="text-white">0 BUSD</span>
                    </div>
                  </div>
                  <div className="d-flex mt-3 justify-content-between">
                    <div className="">
                      <NavLink to="/launchpad"
                        className="btn_progress text-decoration-none"
                      >
                        Launch Pad
                      </NavLink>
                    </div>

                    <div className="icons">
                      <TwitterIcon className="text-white" />
                      <TelegramIcon className="text-white ms-3" />
                      <LanguageIcon className="text-white ms-3" />
                    </div>
                  </div>
            </div>
          </div>
          <div className="col-12 p-lg-0 p-3">
          <div className="box_card mx-auto">
            <div className="d-flex">
                    <div className="col-3">
                       <img src={cardimg} alt="img" className="card_img" />
                     </div>
                     <div className="ms-4 col-5">
                       <h5 className="">Animalia</h5>
                       <h6>Registration in:</h6>
                     </div>
                     <div className="col-lg-3 col-4 text-end">
                       <img src={ethereum} alt="" className="ete_ion" />
                     </div>
                   </div>

               <p className="mt-2"> Animalia is an independent free-to-play online Nft trading card game featuring crypto-inspired meme creatures and gemstones. Powered by the BNB Chain </p>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>50K</p>
                </div>
                <div className="d-flex justify-content-between">
                    <h5>
                        BUSD
                    </h5>
                    <p>TBA</p>
                </div>
                <div className="d-block justify-content-between">
                    <h5>
                        TBA
                    </h5>
                    <p>WhiteList Coming Soon</p>
                </div>
                  <div className="item-progress">
                    <div className="progress mt-4 mt-md-5">
                      <div className="progress-bar w-75" role="progressbar">
                        80%
                      </div>
                    </div>
                    <div className="progress-sale d-flex justify-content-between mt-3">
                      <span className="text-white">0/94,752 MECHA</span>
                      <span className="text-white">0 BUSD</span>
                    </div>
                  </div>
                  <div className="d-flex mt-3 justify-content-between">
                    <div className="">
                      <NavLink to="/launchpad"
                        className="btn_progress text-decoration-none"
                      >
                        Launch Pad
                      </NavLink>
                    </div>

                    <div className="icons">
                      <TwitterIcon className="text-white" />
                      <TelegramIcon className="text-white ms-3" />
                      <LanguageIcon className="text-white ms-3" />
                    </div>
                  </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}


                