import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../Navbar/navbar.css'
const WhiteListContributors = (props: any) => {
  return <div>
    <Modal size="lg" className="bg-dark" show={props.showContributors} centered>
      <Modal.Header closeButton>
        <Modal.Title>Contributors</Modal.Title>
        {/* <button type="button" className="btn-close" aria-label="Close"></button> */}
      </Modal.Header>
      <Modal.Body>
        <table className="table table align-middle">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Address({props.listOfContributors.length})</th>
            </tr>
          </thead>
          <tbody>
            {
              props.listOfContributors.map((address: any, index: number) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td className="">{address}</td>
                  </tr>
                )
              })
            }

          </tbody>
        </table>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => props.handleCloseContributors()}>
          ok
        </Button>

      </Modal.Footer>
    </Modal>
  </div>;
};

export default WhiteListContributors;
