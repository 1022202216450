import Offcanvas from "react-bootstrap/Offcanvas";
import { MobileNavBar } from "../../interfaces/navbar";
import finallogo from "../../assets/finallogo.gif";
import blacklogo from "../../assets/blacklogo.gif";
import { navData } from "./Navdata";
import logo from "../../assets/logo/new-logo.png";
import { NavLink } from "react-router-dom";
const MobileNavbar = ({ show, handleClose }: MobileNavBar) => {
  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} className="clr_blak">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">
          <img
                src={localStorage.getItem("theme") === "dark-theme" ? finallogo : blacklogo}
                alt="logo"
                width="120px"
              />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {navData.map((item) => (
            <NavLink
              key={item.id}
              className={"sideitem"}
              to={item.link}
              onClick={() => handleClose()}
            >
              {item.icon}
              <span className={"linkText"}>{item.text}</span>
            </NavLink>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default MobileNavbar;
