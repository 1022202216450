import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const EmergenyWithdraw = (props: any) => {
    return <div>
        <Modal show={props.showEmergenyWithdraw} centered>
            <Modal.Header >
                <Modal.Title>Confirm Emergency Withdraw</Modal.Title>
            </Modal.Header>
            <Modal.Body>Emergency withdrawal takes your contribution (with {props.penalityFee}% penalty) out of Presale Pool and cancels your participation in the presale</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.handleClose()}>
                    cancel
                </Button>
                <Button variant="primary" onClick={() => { props.emergenyWithdrawContribute(); props.handleClose() }}>
                    ok
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default EmergenyWithdraw;
