import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {getPrivateLaunchapads, getPrivateMyContributions } from './helperFunctionLaunchpadData'
import { string } from 'yup'

const initialState = {
  allLaunchpad: <any>[],
  myContribution: <any>[],
  upComing: [''],
  live: [''],
  filled: [''],
  ended: [''],
  cancelled: [''],
  isLaunchpadDataLoading: false,
  activeTab: 'allLaunchpad',
  filterName: 'my',
  activeFilter: 'all',
  launcpadType: '',
}
export const PrivatelaunchpadData = createSlice({
  name: 'PrivatelaunchpadData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateLaunchpadType: (state, action: PayloadAction<string>) => {
      state.launcpadType = action.payload
    },
    updateActiveFilter: (state, action: PayloadAction<string>) => {
      console.log('action.payload', action.payload)
      state.activeFilter = action.payload
    },
    filterNameTab: (state, action: PayloadAction<string>) => {
      state.filterName = action.payload
    },
    updateTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    updateUpComing: (state, action: PayloadAction<string>) => {
      state.upComing.push(action.payload)
    },
    empty: (state) => {
      state.ended = []
      state.filled = []
      state.live = []
      state.upComing = []
      state.cancelled = []
      state.myContribution = []
      state.allLaunchpad = []
    },
    updateLive: (state, action: PayloadAction<string>) => {
      state.live.push(action.payload)

    },
    updateFilled: (state, action: PayloadAction<string>) => {
      state.filled.push(action.payload)

    },
    updateCancelled: (state, action: PayloadAction<string>) => {
      state.cancelled.push(action.payload)

    },
    updateEnded: (state, action: PayloadAction<string>) => {
      state.ended.push(action.payload)

    },
    upDateFilter: (state, action: PayloadAction<any[]>) => {
      console.log('upDateFilter', action.payload)
      state.allLaunchpad = action.payload
    },
    upDateFilterForMyContribution: (state, action: PayloadAction<any[]>) => {
      console.log('upDateFilterForMyContribution', action.payload)
      state.myContribution = action.payload
    }
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: {
    [getPrivateLaunchapads.pending.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getPrivateLaunchapads.fulfilled.type]: (state: any, { payload }: any) => {
      console.log('payload', payload)
      state.allLaunchpad = payload
      state.isLaunchpadDataLoading = false
    },
    [getPrivateLaunchapads.rejected.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getPrivateMyContributions.pending.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
    [getPrivateMyContributions.fulfilled.type]: (state: any, { payload }: any) => {
      state.myContribution = payload
      state.isLaunchpadDataLoading = false
    },
    [getPrivateMyContributions.rejected.type]: (state: any) => {
      state.isLaunchpadDataLoading = false
    },
  }
})
export const { updateActiveFilter, updateLaunchpadType, updateUpComing, updateEnded, updateLive, updateCancelled, updateFilled, upDateFilter, updateTab, upDateFilterForMyContribution, empty, filterNameTab } = PrivatelaunchpadData.actions
export default PrivatelaunchpadData.reducer