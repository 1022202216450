import React from "react";
import Countdown from "react-countdown";

const Counter = ({ initialTime, updateGetInfo, setUpdateGetInfo }: { initialTime: number, updateGetInfo: Boolean, setUpdateGetInfo: Function },) => {
    console.log("initialTime", initialTime);

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            setUpdateGetInfo(!updateGetInfo)

        } else {
            return (<>
                <div className="box_time">{days}</div>
                <div className="box_time">{hours}</div>
                <div className="box_time">{minutes}</div>
                <div className="box_time">{seconds}</div>
            </>
            )
        }
    };
    return <div>
        <div className="d-flex  justify-content-center gap-3 text-white">
            <Countdown
                date={
                    Date.now() + (initialTime - Date.now())
                }
                key={initialTime}
                renderer={renderer}
            />
        </div>
    </div>;
};

export default Counter;
